.about-all h3 {
    font-size: 35px;
    color: var;
    color: var(--sixth-color);
    font-family: "poppins-semibold";
    padding-bottom: 12px;
}
.about-all p {
    font-size: 14px;
    color: ver(--second-color);
    color: var(--second-color);
    font-family: 'poppins-regular';
}
.about-img img {
    width: 100%;
    border-radius: 20px;
    box-shadow: 0px 0px 10px #0000003d;
}
.about-sec .row {
    display: flex;
    align-items: center;
}
.about-sec {
    padding: 70px 0px;
    position: relative;
}

/* Begin of Best Product Section */
.best-product-all {
    background-color: var(--first-color);
    border-radius: 20px;
    padding: 50px;
}
.best-pro-all h4 {
    font-size: 40px;
    color: var(--third-color);
    font-family: 'poppins-semibold';
    text-transform: capitalize;
}
.best-pro-all p {
    font-size: 12px;
    color: var(--third-color);
    font-family: 'poppins-regular';
}
.best-pro-all {
    text-align: center;
}
.all-help-customer h4 {
    font-size: 34px;
    color: var(--first-color);
    font-family: 'poppins-semibold';
}
.all-help-customer p {
    font-size: 15px;
    color: var(--second-color);
    font-family: 'poppins-regular';
}
.help-customer-sec {
    padding: 70px 0px;
}
.all-help-customer {
    box-shadow: 0px 4px 20px #0000002b;
    padding: 50px;
    border-radius: 20px;
}
/* ================ */

/* Begin of Responsive Section */
@media (max-width: 1920px) {

}
@media (max-width: 1600px) {

}
@media (max-width: 1440px) {

}
@media (max-width: 1366px) {

}
@media (max-width: 1280px) {

}
@media (max-width: 1100px) {
    .about-all h3 {
        font-size: 30px;
    }
    .about-all p {
        font-size: 11px;
    }
    .best-pro-all h4 {
        font-size: 35px;
        
    }
    .best-pro-all p {
        font-size: 10px;
    }
    .all-help-customer h4 {
        font-size: 28px;
    }
    .all-help-customer p {
        font-size: 10px;
    }
    .about-sec {
        padding: 60px 0px;
    }
}
@media (max-width: 1024px) {
    .about-all h3 {
        font-size: 23px;
    }
    .about-all p {
        font-size: 9px;
    }

}
@media (max-width: 991px) {
    .all-help-customer {
        padding: 50px 23px;
       
    }
    .all-help-customer h4 {
        font-size: 21px;
    }

}
@media (max-width: 820px) {

}
@media (max-width: 768px) {

}
@media (max-width: 575px) {
    .about-sec {
        padding: 30px 0px;
        text-align: center;
    }
    .about-all h3 {
        font-size: 20px;
    }
    .best-product-all {
        padding: 50px 0px;
    }
    .best-pro-all h4 {
        font-size: 27px;
    }
    .all-help-customer {
        padding: 50px 16px;
        text-align: center;
        margin-bottom: 20px;
    }
    .all-help-customer h4 {
        font-size: 20px;
    }
    .help-customer-sec {
        padding: 40px 0px;
    }

}
/* ===================== */