.login-sec {
    background-image: url(../img/Home/loginback.webp);
    width: 100%;
    height: 100vh;
    background-size: 100% 100%;
    /* padding: 70px 0px; */
    padding-bottom: 40px;
    padding-top: 70px;
}
section.login-sec.sign-in-sec .row {
    display: flex;
    align-items: end;
}
.form-login-input input {
    width: 100%;
    margin: 13px 0px;
    border: none;
    padding: 9px 20px;
    border-radius: 10px;
    outline: none;
    position: relative;
}
.form-login-input {
    position: relative;
}
.password-icon {
    position: absolute;
    top: 23px;
    right: 15px;
}
.login-form {
    background-color: #0000005e;
    padding: 30px 30px;
    border-radius: 20px;
    width: 80%;
    margin: 0 auto;
    z-index: 1;
    position: relative;
}
.login-signup-btn a {
    font-size: 15px;
    font-family: "poppins-regular";
    background-color: var(--first-color);
    padding: 10px 40px;
    color: var(--third-color);
    text-decoration: unset;
    border-radius: 7px;
}
.login-signup-btn {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;
}
.form-login-input input::placeholder {
    font-size: 12px;
    color: #6C757D;
    font-family: 'poppins-regular';
}
.form-head h3 {
    font-size: 24px;
    color: #F2F2F2;
    font-family: poppins-bold;
    margin: 0;
}
.form-head {
    text-align: center;
    padding: 25px 0px;
}
.welcome-back-sec h3 {
    font-size: 52px;
    color: #F2F2F2;
    font-family: 'poppins-bold';
    margin: 0;
}
.welcome-back-sec p {
    font-size: 11px;
    color: var(--third-color);
    width: 100%;
    font-family: 'poppins-regular';
    margin: 0;
    padding: 10px 0px;
}
.login-signin-btn a {
    font-size: 16px;
    font-family: "poppins-regular";
    background-color: var(--first-color);
    padding: 10px 40px;
    color: var(--third-color);
    text-decoration: unset;
    border-radius: 7px;
}
.login-signin-btn {
    margin: 20px 0px;
}
.div-for-all-movement {
    position: relative;
}
/* .welcome-back-movement {
    position: absolute;
    top: 370px;
} */
section.login-sec.sign-up-sec .row {
    display: flex;
    align-items: end;
}
/* .login-page-logo {
    position: absolute;
    top: 70px;
} */
/* .welcome-back-sec {
    padding: 40px 0px;
} */
.login-page-logo {
    position: absolute;
    top: 70px;
}
.password-icon i {
    color: #B5B5B5;
}
/* Begin of Sign in Css */
.forgot-password-link a {
    font-size: 10px;
    color: var(--third-color);
    font-family: 'poppins-regular';
    text-decoration: underline;
}
.remeber-forget {
    display: flex;
    justify-content: space-between;
}
 
.login-sec input[type=checkbox] {
    position: relative;
    border: 2px solid #ffffff;
    border-radius: 5px;
    background: none;
    cursor: pointer;
    line-height: 0;
    margin: 0 .6em 0 0;
    outline: 0;
    padding: 0 !important;
    vertical-align: bottom;
    height: 20px;
    width: 20px;
   
   
}
  
.login-sec input[type=checkbox]:hover {
    opacity: 1;
  }
  
  .login-sec input[type=checkbox]:checked {
    background-color: transparent;
    opacity: 1;
  }
  

  .remeber-check-box label {
    font-size: 10px;
    color: var(--third-color);
    font-family: 'poppins-regular';
    margin: 0;
}
/* .sign-in-sec {
    padding-bottom: 480px;
} */
/* .welcome-back-sec-signin p {
    width: 55%;
} */
/* .welcome-back-signin-movement {
    position: absolute;
    top: 280px;
} */
/* =============== */
/* Begin of Enter Email Css */
.form-head p {
    font-size: 14px;
    color: var(--third-color);
    font-family: 'segoe-regular';
    margin: 0;
    padding-top: 10px;
    font-weight: 100;
}
.enter-email-para p {
    font-size: 13px;
    color: var(--third-color);
    font-family: 'segoe-regular';
    margin: 0;
    width: 80%;
    margin: 0 auto;
}
.enter-email-para {
    text-align: center;
}
/* .enter-email-movement {
    position: absolute;
    top: 200px;
} */
/* ============= */

/* Begin of Enter otp */
.send-again-btn button {
    background-color: transparent;
    font-size: 12px;
    color: var(--third-color);
    font-family: 'poppins-regular';
    border: unset;
    text-decoration: underline;
    text-transform: capitalize;
}
.send-again-btn {
    text-align: center;
}
/* ================ */

/* Begin of Responsive Section */
@media (max-width: 1920px) {

}
@media (max-width: 1600px) {

}
@media (max-width: 1440px) {

}
@media (max-width: 1366px) {

}
@media (max-width: 1280px) {

}
@media (max-width: 1100px) {
    .welcome-back-sec-signin p {
        width: 47%;
    }
   
}
@media (max-width: 1024px) {
 

}
@media (max-width: 991px) {
    .login-form {
        width: 100%;
    }
    /* .welcome-back-sec-signin p {
        width: 37%;
    } */
    .welcome-back-sec h3 {
        font-size: 42px;
    }

}
@media (max-width: 820px) {
    /* .welcome-back-sec-signin p {
        width: 47%;
    } */
    /* .welcome-back-signin-movement {
        position: absolute;
        top: 272px;
    } */

}
@media (max-width: 768px) {

}
@media (max-width: 575px) {
    /* .login-form {
        width: 100%;
        margin-top: 10px;
        padding: 20px 10px;
    }
    .welcome-back-signin-movement {
        position: absolute;
        top: 132px;
    }
    .login-sec {
        padding-bottom: 700px;
        padding-top: 0px;
    }
    .welcome-back-sec-signin p {
        width: 100%;
    }
    .welcome-back-sec h3 {
        font-size: 32px;
    }
    .login-page-logo {
        text-align: center;
        padding: 15px 0px;
    }
    .enter-email-movement {
        position: absolute;
        top: 380px;
        text-align: center;
    }
    .enter-email-para p {
        font-size: 11px;
        width: 100%;
        padding: 20px 0px;
    }
    .welcome-back-signin-movement {
        position: absolute;
        top: 372px;
        text-align: center;
    }
    .form-head {
        text-align: center;
        padding: 0px 0px;
    }
    .login-signup-btn {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .form-login-input input {
        margin: 7px 0px;
        padding: 6px 20px;
        border-radius: 6px;
   
    }
    .sign-up-all {
        position: absolute;
        top: 510px;
        text-align: center;
    }
    .create-newpass-res {
        position: absolute;
        top: 340px;
        text-align: center;
    }
    .create-new-password-sec {
        padding-bottom: 540px;
        padding-top: 0px;
    }
    .form-head h3 {
        font-size: 16px;
        padding-bottom: 10px;
    }
    .password-icon {
        position: absolute;
        top: 12px;
        right: 15px;
    }
    .enter-email-sec {
        padding-bottom: 580px;
        padding-top: 0px;
    }
    .sign-in-sec {
        padding-bottom: 570px;
        padding-top: 0px;
    }
    .enter-otp-sec {
        padding-bottom: 880px;
        padding-top: 0px;
    }
    .enter-otp-res {
        position: absolute;
        top: 680px;
        text-align: center;
    } */
    .login-page-logo {
        position: absolute;
        top: 0px;
        text-align: center;
    }
    .welcome-back-sec h3 {
        font-size: 34px;
    }
    .login-sec {
        overflow-x: scroll;
        text-align: center;
    }
    .welcome-back-sec p {
        font-size: 9px;
    }
    .login-signin-btn a {
        font-size: 12px;
        padding: 10px 34px;
    }
    .login-form {
        padding: 20px 10px;
       
    }
    .welcome-back-sec-signin p {
        width: 100%;
    }
    .welcome-back-sec {
    padding: 40px 0px;
}
.login-sec {
    height: 100%;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
    padding-bottom: 110px;
    /* padding-top: 160px; */
    position: fixed;
}

}
/* ===================== */