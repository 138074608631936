.edit-profile-card {
  background: #fff;
  padding: 43px 40px;
  border-radius: 22px;
  margin-top: -137px !important;
  box-shadow: 0px 0px 5px 4px #d3d3d345;
}
.edit-profile-card .edit-heading-wrapper h2 {
  font-size: 40px;
  font-weight: 600;
  font-family: "poppins-semibold";
  padding-bottom: 20px;
}
.edit-details-wrapper .detail-heading-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
}
.edit-details-wrapper .detail-heading-wrapper button.edit {
  background: #7dab3c;
  border: 0px;
  padding: 8px 33px;
  border-radius: 7px;
  color: #fff;
  font-family: "poppins-regular";
}
.edit-details-wrapper .detail-heading-wrapper h3 {
  font-family: "poppins-semibold";
  font-size: 30px;
}
.edit-profile-card .edit-details-wrapper {
  padding: 24px 30px;
  border: 1px solid #7dab3c;
  margin-top: 30px;
  border-radius: 10px;
}
.edit-profile-card .edit-details-wrapper .detail-content-wrapper ul {
  padding: 0px;
  list-style: none;
  margin-bottom: 0px;
}
.edit-profile-card .edit-details-wrapper .detail-content-wrapper ul li {
  padding: 10px 0px;
  border-bottom: 1px solid #ecf3e2;
  display: flex;
  align-items: baseline;
}
.edit-profile-card
  .edit-details-wrapper
  .detail-content-wrapper
  ul
  li
  span.detail-icon {
  margin-right: 13px;
  color: #7dab3c;
}
.edit-profile-card
  .edit-details-wrapper
  .detail-content-wrapper
  ul
  li
  span.txt {
  font-size: 16px;
  font-family: "poppins-regular";
  font-weight: 400;
}
.edit-profile-card .edit-heading-wrapper {
  text-align: center;
}
.Business-detail .detail-content-wrapper li {
  padding: 20px 0px !important;
}
.detail-content-wrapper .form-group ul li {
  border: 0px !important;
}
.detail-content-wrapper .form-group {
  margin-left: 38px;
  border-bottom: 1px solid #ecf3e2;
}
.Password-wrap input.form-control {
  height: 50px;
  box-shadow: 0px 0px 5px 4px #d3d3d345;
  border: 0px;
  padding: 10px 30px;
  background-image: url("../img/seller-profile/key.png");
  background-repeat: no-repeat;
  background-position: 3% center;
}
.Password-wrap .eye-wrapper {
  position: absolute;
  top: 26%;
  right: 6%;
}
.Password-wrap .eye-wrapper i {
  color: #b5b5b5;
}
/* ======================== */

/* Begin of Adding Product Css */
.mcq-dv-input input {
  width: 47%;
  border: none;
  border-radius: 7px;
  background-color: #F3F3F3;
  padding: 4px 10px;
  position: absolute;
  right: 10px;
  bottom: 8px;
  outline: none;
  box-shadow: 5px 6px 10px  #0000002e inset;
}
.mcq-before::before {
  content: '≥';
  position: absolute;
  color: #70707054;
  left: 90px;
  bottom: 4px;
  font-size: 21px;
  top: 9px;
}
.mcq-dv-input {
  box-shadow: 0px 3px 10px #00000047;
  border-radius: 5px;
  padding: 12px 14px;
  position: relative;
}
.mcq-dv-input label {
  font-size: 11px;
  color: #6C757D;
  font-family: 'poppins-regular';
  margin: 0;
}
.mcq-dv-input input::placeholder {
  text-align: center;
}
.mcq-dv-all h5 {
  font-size: 20px;
  color: var(--second-color);
  font-family: 'poppins-semibold';
}
.mcq-dv-all {
  padding-top: 40px;
}
.mcq-dv-all h5 {
  font-size: 23px;
  color: var(--second-color);
  font-family: 'poppins-semibold';
}
.label-and-upload-product-btn {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.label-and-upload-product-btn label {
  font-size: 14px;
  color: #6C757D;
  font-family: 'poppins-regular';
  margin: 0;
}
.upload-btn {
  margin-left: 10px;
}
.upload-btn button {
  background-color: var(--first-color);
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  color: var(--third-color);
  font-family: 'poppins-regular';
  width: 100%;
  position: relative;
}
.upload-btn input[type="file"] {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 16px;
}
.all-img-with-shadow {
  box-shadow: 0px 0px 10px #00000061;
  padding: 16px;
  width: 14%;
  text-align: center;
  height: 96px !important;
  border-radius: 8px;
  margin: 0px 5px;
}
.img-with-shadow-pop-up {
  height: 100px !important;
}
.label-and-upload-box-shadow {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}
.all-select-product-table table {
  width: 100%;
}
.all-select-product-table td {
  border: 1px solid #dcdcdc;
  padding: 10px;
}
.table-input input {
  border: 1px solid #dcdcdc;
  outline: none;
}
.all-select-product-table th {
  border: 1px solid #dcdcdc;
  padding: 10px;
  text-align: center;
  font-size: 13px;
  color: var(--second-color);
  font-weight: 500;
  text-transform: capitalize;
}
.color-heading h5 {
  font-size: 20px;
  color: #000000;
  font-family: 'poppins-regular';
  text-align: center;
}
.movement-of-react-tags {
  padding-top: 30px;
}
.ReactTags__tags.react-tags-wrapper {
  position: relative;
}
.ReactTags__selected button {
  background-color: #7dab3c;
  border: none;
  height: 33px;
  color: var(--third-color);
  cursor: pointer !important;
}

.ReactTags__selected {
  position: absolute;
  right: 0;
  /* left: 0; */
  background-color: #7dab3c;
  color: var(--third-color);
  font-family: 'poppins-regular';
  text-transform: capitalize;
  padding: 0px 10px;
}
.color-heading h5 {
  font-size: 16px;
  font-family: 'poppins-meduim';
  text-transform: capitalize;
  margin: 0;
  padding: 7px 6px;
}
.color-heading {
  border: 1px solid #dcdcdc;

}
.all-color-size-input input {
  width: 100%;
  border: 1px solid #dcdcdc;
  border-radius: unset;
}
.ReactTags__tagInput input {
  height: 35px;
  outline: none;
}
.ReactTags__tagInput input::placeholder {
  opacity: 0;
}
.all-select-product-table {
  padding-top: 0px;
  width: 100%;
  overflow-x: scroll;
}

.all-select-product-table::-webkit-scrollbar {
  width: .3em;
  height: 5px;

}
.or-text h1 {
  font-size: 26px;
}

.all-select-product-table::-webkit-scrollbar-thumb {
  background-color: var(--first-color);
    border-radius: 10px;
  
  /* outline: 1px solid slategrey; */
}
.adding-product-scroll {
  height: 880px;
  overflow-x: auto;
  margin-top: -1px !important;
}
.adding-main-product-scroll{
  height: 880px;
  overflow-x: auto;
}
.adding-seller-business-profile {
  height: 774px;
  overflow-x: auto;
}
.adding-seller-business-profile::-webkit-scrollbar {
  width: 1px !important;
}
.adding-main-product-scroll::-webkit-scrollbar {
  width: 1px !important;
}

.adding-product-scroll::-webkit-scrollbar {
  width: 1px !important;
}
 div#exampleModal::-webkit-scrollbar {
  width: 1px !important;
}
.color-size-small {
  font-size: 11px;
}

/* ============== */

/* Begin of Check Box Css */
.all-name-and-things h3 {
  font-size: 13px;
  color: var(--second-color);
  font-family: 'poppins-semibold';
  line-height: 26px;
  margin: 0;
}
.checkbox-border {
  border: 1px solid #dcdcdc;
  padding: 30px 10px;
  margin: 1px 1px;
  margin-top: 0;
  border-radius: 8px;
}
#subscribe {
  margin-right: 8px;
  accent-color: #7dab3c;
}
.set-all-price {
  font-size: 22px;
  color: var(--second-color);
  font-family: 'poppins-semibold';
  padding-top: 30px;
}
.check-quantity input {
  width: 100%;
  border: none;
  box-shadow: 0px 2px 8px #00000038;
  height: 40px;
  outline: none;
}
.check-quantity label {
  font-size: 14px;
  font-family: 'poppins-semibold';
  text-transform: capitalize;
  padding-right: 7px;
}
.check-box-button button {
  font-size: 12px;
  background: var(--first-color);
  padding: 10px;
  width: 30% !important;
  margin: 22px 0px;
  border: none;
  color: var(--third-color);
  font-family: 'poppins-meduim';
  border-radius: 6px;
}
.all-name-and-things {
  /* border: 1px solid #dcdcdc; */
  padding-bottom: 10px;
}
.check-name-moq {
  margin: 20px 0px;
}
.for-upper-margin-in-mcq {
  margin-top: 20px;
}
.label-in-adding-prdoct label {
  padding-bottom: 6px;
  font-size: 12px;
}
/* ================ */

/* Begin of OR Css */
.or-text {
  padding: 10px 0px;
}
.or-text h1 {
  position: relative;
}
/* =============== */

/* Begin of Responsive Section */
@media (max-width: 1920px) {

}
@media (max-width: 1600px) {

}
@media (max-width: 1440px) {

}
@media (max-width: 1366px) {
  .check-box-button button {
   
    width: 40%;
   
}
.all-img-with-shadow {
  height: 76px !important;
}

}
@media (max-width: 1280px) {
  .order-activity-wrapper
  .order-stats-wrapper
  .order-stat-wrapper
  .order-content-wrapper
  h2 {
    font-size: 15px;
}
.order-activity-wrapper
  .order-stats-wrapper
  .order-stat-wrapper
  .order-content-wrapper
  h6 {
    font-size: 8px;
}

}
@media (max-width: 1100px) {
  .seller-header-sec .seller-profile-name-wrapper .seller-img-wrapper img {
    height: 49px;
    width: 59px;
}
.seller-header-sec .seller-profile-name-wrapper .seller-content-wrapper h4 {
  font-size: 17px;
}
.seller-header-sec .seller-profile-name-wrapper .seller-content-wrapper h6 {
  font-size: 11px;
  
}
.mcq-dv-all h5 {
  font-size: 19px;
}
.all-img-with-shadow {
  height: 66px;
}
   
}
@media (max-width: 1024px) {
  .seller-sidebar-sec .sidebar-links-wrapper ul li.sidebar-links a span.text {
    padding: 23px 20px;
    font-size: 13px;
}
.order-activity-wrapper
  .order-stats-wrapper
  .order-stat-wrapper
  .order-content-wrapper {
    padding-left: 10px;
}
 

}
@media (max-width: 991px) {
  .order-activity-wrapper {
    margin-top: 33px;
}
.seller-header-sec .seller-profile-logo-wrapper {
  display: none;
}
.edit-profile-card {
  margin-top: 23px;
}
.all-img-with-shadow {
  height: 94px;
}

}
@media (max-width: 820px) {

}
@media (max-width: 768px) {
  .all-img-with-shadow {
    height: 74px;
}
}
@media (max-width: 575px) {
  .order-activity-wrapper {
    padding: 27px 10px;

}
.edit-profile-card {
  padding: 43px 10px !important;
}
.edit-profile-card .edit-details-wrapper {
  padding: 24px 13px;
}
.edit-details-wrapper .detail-heading-wrapper h3 {
  font-size: 16px;
}
.edit-profile-card .edit-heading-wrapper h2 {
  font-size: 22px;
}
.edit-profile-card
  .edit-details-wrapper
  .detail-content-wrapper
  ul
  li
  span.txt {
    font-size: 10px;
}
.mcq-dv-all h5 {
  font-size: 16px;
}
.all-img-with-shadow {
  height: 114px !important;
  width: 100%;
  margin: 20px 0px;
}
.label-and-upload-product-btn {
  display: block;
}
.label-and-upload-box-shadow {
  display: block;
}
.mcq-dv-input {
  margin: 15px 0px;
}
.mcq-dv-input label {
  font-size: 10px;
}
.upload-btn {
  margin-left: 0px;
  width: 100%;
}
.all-select-product-table {
  overflow-x: scroll;
}
.detail-content-wrapper .form-group {
  border-bottom: 1px solid #ecf3e2;
  margin: unset;
}
.for-no-padding {
  padding: 0;
}
.edit-details-wrapper .detail-heading-wrapper button.edit {
  font-size: 12px;
  padding: 6px 27px;

}
.all-select-product-table {
  padding-top: 40px;
  margin-bottom: 20px;
}
.check-seller input[type=checkbox] {

  margin: 0 .1em 0 0;

  height: 20px;
  width: 10px;

}
.edit-profile-card {
  margin-bottom: 50px;
}
.checkbox-border {
  border: 1px solid #dcdcdc;
  padding: 30px 0px;
  margin: 1px 1px;
  margin-top: 30px;
  border-radius: 8px;
}
.check-box-button button {
  width: 100% !important;

}
.set-all-price {
  font-size: 17px;
}
.check-quantity input {
  width: 100%;
}
.check-quantity label {
  padding: 10px 0px;
}
}
/* ===================== */