.wish-list-sec .wish-list-table {
    width: 100%;
}

.img-icon img {
    width: 60%;
    border-radius: 8px;
    box-shadow: 0px 0px 10px #0000004a;
}

table.wish-list-table thead th span {
    display: block;
    width: 220px;
    font-weight: 100;
    margin: 0 auto;
}

.img-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.wish-list-sec th {
    text-align: center;
}

.wish-list-sec td {
    text-align: center;
}

.wish-list-all td {
    font-size: 15px;
    color: var(--second-color);
    font-family: 'poppins-regular';
    margin: 0;
}

.wish-list-all td span {
    color: #F12548;
    font-weight: 600;
}

.wish-list-all thead {
    background-color: var(--second-color);
    color: var(--third-color);

}

.wish-list-sec th {
    font-size: 14px;
    font-family: 'poppins-regular';
    padding: 8px 0px;
    width: 200px;
    font-weight: 100;
}
.wish-list-sec {
    padding: 20px 0px;
}

.wish-list-all td a {
    font-size: 14px;
    background-color: var(--first-color);
    color: var(--third-color);
    padding: 7px 12px;
    border: unset;
    border-radius: 7px;
    text-decoration: unset;
}

.wish-list-all tr {
    border-bottom: 1px solid #3737375e;
}

.wish-list-all td {
    font-size: 15px;
    color: var(--second-color);
    font-family: 'poppins-regular';
    margin: 0;
    padding: 16px 0px;
    vertical-align: middle;
}

.no-border-row {
    border-bottom: none !important;
}

/* Begin of Cart Css  */
.cart-text p {
    font-size: 16px;
    color: #989898;
    font-family: 'poppins-meduim';
}

.cart-text-two {
    text-align: end;
}

.total-all-cost h5 {
    font-size: 16px;
    color: #EF5D61;
    font-family: 'poppins-meduim';
    margin: 0;
}

.for-total-text {
    text-align: end;
}

.continue-check-out a {
    font-size: 15px;
    color: var(--third-color);
    font-family: 'poppins-regular';
    background-color: var(--first-color);
    padding: 10px 20px;
    text-decoration: unset;
    border-radius: 8px;
}

.continue-check-out {
    text-align: end;
    padding-top: 35px;
}

.for-back-all {
    background-color: #F2F2F2;
    padding: 35px 20px;
    border-radius: 16px;
}

.for-borders-row {
    border-top: 1px solid #0000001a;
    border-bottom: 1px solid #0000002b;
}

.total-all-cost {
    padding: 10px 0px;
}

.continue-or-check-out-btn a {
    font-size: 14px;
    background-color: var(--first-color);
    padding: 10px 30px;
    color: var(--third-color);
    font-family: 'poppins-regular';
    text-transform: capitalize;
    text-decoration: unset;
    border-radius: 10px;
    margin: 0px 10px;
    transition: 1s;
}

.continue-or-check-out-btn a:hover {
    background-color: var(--second-color);
}

.wish-list-all td p {
    margin: 0;
}

.quantity-for-cart {
    display: initial;
}

.for-upper-space {
    padding-top: 50px;
}

/* =================== */

/* Begin of Responsive Section */
@media (max-width: 1920px) {}

@media (max-width: 1600px) {}

@media (max-width: 1440px) {}

@media (max-width: 1366px) {}

@media (max-width: 1280px) {}

@media (max-width: 1100px) {}

@media (max-width: 1024px) {}

@media (max-width: 991px) {}

@media (max-width: 820px) {}

@media (max-width: 768px) {}

@media (max-width: 575px) {
    .wish-list-all {
        overflow: scroll;
    }
    .wish-list-sec .wish-list-table {
        width: 100%;
        min-width: 620px;
    }
    .continue-or-check-out-btn a {
        font-size: 13px;
        padding: 19px 23px;
        width: 100%;
        display: block;
        margin: 10px 0px;
        text-align: center;
    }
    .for-upper-space {
        padding-top: 28px;
    }
    .for-back-all {
        margin-top: 30px;
    }
    .wish-list-sec {
        padding-top: 10px;
    }
    .cart-text-two {
        text-align: initial;
    }
    .for-total-text {
        text-align: initial;
    }
    .continue-check-out {
        text-align: initial;
    }
    .for-responsive-movment-in-cart {
        position: relative;
        bottom: 39px;
    }
    .for-responsive-cart3 {
        position: relative;
        top: 39px;
    }
}

/* ===================== */