.contact-form-input input {
    width: 100%;
    padding: 8px;
    margin: 10px 0px;
}
.contact-form {
    background-color: var(--back-color);
    padding: 50px;
}
.contact-form-input input {
    width: 100%;
    padding: 10px 20px;
    margin: 10px 0px;
    border: none;
    box-shadow: 0px 5px 10px #00000054;
    border-radius: 7px;
}
.contact-form-input textarea {
    margin: 10px 0px;
    height: 160px;
    resize: none;
    border: none;
    box-shadow: 0px 0px 10px #0000003b;
    border-radius: 7px;
    outline: none;
    padding: 10px 20px;
}
.contact-btn button {
    background-color: var(--first-color);
    width: 30%;
    border: none;
    padding: 8px;
    color: var(--third-color);
    border-radius: 7px;
    margin-top: 10px;
}
.contact-form {
    background-color: var(--back-color);
    padding: 50px;
    border-radius: 40px;
    width: 70%;
    margin: 0 auto;
}
.contact-form-para p {
    font-size: 22px;
    color: var(--second-color);
    font-family: 'poppins-meduim';
}
.contact-form-input input::placeholder {
    font-size: 12px;
    color: #00000063;
    font-family: 'poppins-meduim';
}
.contact-form-input textarea::placeholder {
    font-size: 12px;
    color: #00000063;
    font-family: 'poppins-meduim';
}
.contact-form-para {
    text-align: center;
}
.contact-btn {
    text-align: center;
}
.contact-icon i {
    font-size: 40px;
    color: var(--third-color);
    background-color: var(--first-color);
    width: 80px;
    height: 80px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 90px;
    margin: 10px auto;
}
.contact-text a {
    font-size: 16px;
    color: #00000066;
    font-family: 'poppins-semibold';
    margin: 0;
    text-decoration: unset;
}
.contact-text p {
    font-size: 16px;
    color: #00000066;
    font-family: 'poppins-semibold';
    margin: 0;
    text-decoration: unset;
}
.contact-icon-text {
    text-align: center;
}
.contact-icon-text-all {
    padding: 50px 170px;
}
.contact-sec {
    padding: 0px;
}
.wish-list-sec {
    padding: 60px 0px;
    padding-top: 10px;
}

/* Begin of Responsive Section */
@media (max-width: 1920px) {

}
@media (max-width: 1600px) {

}
@media (max-width: 1440px) {

}
@media (max-width: 1366px) {

}
@media (max-width: 1280px) {

}
@media (max-width: 1100px) {
    .contact-form {
        width: 80%;
    }
}
@media (max-width: 1024px) {
 

}
@media (max-width: 991px) {
    .contact-form {
        width: 100%;
    }

}
@media (max-width: 820px) {

}
@media (max-width: 768px) {

}
@media (max-width: 575px) {
    .contact-form {
        padding: 50px 6px; 
    }
    .contact-form-para p {
        font-size: 16px;
    }
    .contact-btn button {
        width: 80%;
    }
    .contact-icon-text-all {
        padding: 50px 0px;
    }
}
/* ===================== */