.order-activity-wrapper {
  background: #fff;
  padding: 27px 32px;
  box-shadow: 0px 0px 5px 4px rgb(110 110 110);
  border-radius: 22px;
  margin-top: -137px;
}

.order-activity-wrapper .order-heading-wrapper h2 {
  font-size: 40px;
  font-weight: 600;
  font-family: "poppins-semibold";
}
.order-activity-wrapper .order-heading-wrapper p {
  font-family: "poppins-regular";
  font-size: 15px;
}
.order-activity-wrapper {
  background: #fff;
  padding: 27px 32px;
  border-radius: 22px;
  margin-top: -137px;
  box-shadow: 0px 0px 5px 4px #d3d3d345;
}
.order-activity-wrapper .order-heading-wrapper h2 {
  font-size: 40px;
  font-weight: 600;
  font-family: "poppins-semibold";
}
.order-activity-wrapper .order-heading-wrapper p {
  font-family: "poppins-regular";
  font-size: 15px;
}
.order-activity-wrapper .order-stats-wrapper {
  display: flex;
  align-items: center;
  margin-top: 37px;
}
.order-activity-wrapper .order-stats-wrapper .order-stat-wrapper {
  flex: 0 0 20%;
  display: flex;
  align-items: center;
}
.order-activity-wrapper
  .order-stats-wrapper
  .order-stat-wrapper
  .order-icon-wrapper
  span.order-icon {
    display: flex;
    width: 60px;
    height: 60px;
    background: #f0f0f0;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 5px 4px rgba(229, 229, 229, 1);
}
.order-activity-wrapper
  .order-stats-wrapper
  .order-stat-wrapper
  .order-content-wrapper {
  padding-left: 20px;
}
.order-activity-wrapper
  .order-stats-wrapper
  .order-stat-wrapper
  .order-content-wrapper
  h6 {
    font-size: 13px;
    font-family: 'poppins-meduim';
    margin: 0px;
}
.order-activity-wrapper
  .order-stats-wrapper
  .order-stat-wrapper
  .order-content-wrapper
  h2 {
    font-size: 24px;
    font-family: 'poppins-semibold';
    font-weight: 700;
}
.chart-wrapper {
  padding-top: 30px;
}
.order-activity-no-margin {
  margin-top: 14px;
}
.seller-dashboard-for-background {
  background-color: #EFEFEF;
}

/* Begin of Responsive Section */
@media (max-width: 1920px) {

}
@media (max-width: 1600px) {

}
@media (max-width: 1440px) {

}
@media (max-width: 1366px) {

}
@media (max-width: 1280px) {
  .seller-header-sec .seller-profile-name-wrapper .seller-content-wrapper h4 {
    font-size: 20px;
}
.seller-header-sec .seller-profile-name-wrapper .seller-content-wrapper h6 {
  font-size: 12px;
}

}
@media (max-width: 1100px) {
   
}
@media (max-width: 1024px) {
 

}
@media (max-width: 991px) {

}
@media (max-width: 820px) {

}
@media (max-width: 768px) {

}
@media (max-width: 575px) {
  .order-activity-wrapper .order-stats-wrapper {
    display: block;
}
.order-activity-wrapper
  .order-stats-wrapper
  .order-stat-wrapper
  .order-icon-wrapper
  span.order-icon {
    width: 50px;
    height: 50px;
    
    margin: 10px 0px;
}
.order-activity-wrapper .order-heading-wrapper p {
  font-size: 12px;
}
.order-activity-wrapper .order-heading-wrapper h2 {
  font-size: 34px;
}
canvas {
  height: 170px !important;
}

}
/* ===================== */