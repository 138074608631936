/* Font */
@font-face {
  font-family: "poppins-meduim";
  src: url("../fonts/Poppins-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "poppins-bold";
  src: url("../fonts/Poppins-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "poppins-regular";
  src: url("../fonts/Poppins-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "poppins-semibold";
  src: url("../fonts/Poppins-SemiBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "poppins-light";
  src: url("../fonts/Poppins-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: "segoe-regular";
  src: url("../fonts/segoeui.ttf");
  font-display: swap;
}

@font-face {
  font-family: "segoe-semibold";
  src: url("../fonts/seguisb.ttf");
  font-display: swap;
}

@font-face {
  font-family: "open-sans-bold";
  src: url("../fonts/OpenSans-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "poppins-light";
  src: url("../fonts/Poppins-Light.ttf");
  font-display: swap;
}

/* ======================================== */

/* color */
:root {
  --first-color: #7DAB3C;
  --second-color: #000000;
  --third-color: #ffffff;
  --fourth-color: #F90101;
  --fifth-color: #F6BF02;
  --sixth-color: #485F27;
  --seveth-color: #717171;
  --eigth-color: #49A6A4;
  --ninth-color: #EFEFEF;
  --ninth-color: #EFEFF7;
  --tenth-color: #686868;
  --eleventh-color: #696969;
  --back-color: #F6F6F6;

}

/* ================   */
html{
  overflow-x: hidden;
}
body {
  overflow-x: hidden;
}

figure {
  margin: 0;
}

/* Begin of Mobile Responsive */
.mobile-header-menu .accordion-button:not(.collapsed) {
  color: var(--first-color);
  background-color: var(--sixth-color);
  /* box-shadow: unset; */
  color: var(--third-color);
}
.mobile-header-menu  .accordion-button:not(.collapsed)::after {
  transform: initial;
}
.mobile-header-menu  .accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: unset;
}
.mobile-header-menu  .accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--third-color);
  text-align: left;
  background-color: var(--sixth-color);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  font-family: 'poppins-regular';
  text-transform: capitalize;
}
.mobile-header-menu {
  margin-top: 10px;
}
.mobile-header-menu  .accordion-button::after {
  font-family: 'FontAwesome';
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "\f107 ";
  background-image: unset;
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
.mobile-header-menu  .accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
  border-radius: 10px;
}
/* .mobile-header-menu  button.accordion-button.collapsed::before {
  content: '\f107';
  font-family: 'FontAwesome';
  position: absolute;
  right: 20px;
} */
.mobile-header-menu li.dropdown-item a {
  color: var(--second-color);
}
.for-items-css  .accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 12px;
  font-size: 1rem;
  color: var(--second-color);
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  font-family: 'poppins-semibold';
  text-transform: capitalize;
}

.for-items-css {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: unset;
}
.for-items-css .accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
  padding: 0 10px;
}
.for-items-css .accordion-button:not(.collapsed) {
  color: var(--first-color);
  background-color: var(--third-color);
  box-shadow: unset !important;
  color: var(--second-color);
}
.inner-no-padding {
  padding: 0;
}
.mobile-header-menu  .accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
  padding-top: 0;
}

/* ==================== */
.for-modal-in-middle {
  transform: none;
  display: flex;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  border: none;
}
.header-shop-by-category {
  display: none;
}
.inner-no-padding li a {
  font-size: 12px;
  font-family: 'poppins-regular';
}
/* Begin of Preloader Css */
/** Animating - START */
@keyframes moving {

  0%,
  5% {
    left: 0;
    background-color: #7DAB3C;
  }

  95%,
  100% {
    left: calc(100% - 3rem);
    background-color: #7DAB3C;
  }
}

@keyframes box-2-anim {
  0% {
    transform: scale(1, 1) rotate(0deg);
    bottom: 0;
  }

  5% {
    bottom: 3rem;
  }

  15% {
    bottom: 0;
    transform: scale(1, 1) rotate(-180deg);
  }

  17.001% {
    bottom: 0;
    transform: translate(0.9rem, 0.6rem) scale(1.3, 0.6) translateY(0px) rotate(-180deg);
  }

  25% {
    bottom: 0;
    transform: scale(1, 1) rotate(-180deg);
  }

  66% {
    transform: scale(1, 1) rotate(-180deg);
    bottom: 0;
  }

  71% {
    bottom: 3rem;
  }

  81% {
    bottom: 0;
    transform: scale(1, 1) rotate(0deg);
  }

  83.001% {
    bottom: 0;
    transform: translate(-0.9rem, 0.6rem) scale(1.3, 0.6) translateY(0px) rotate(0deg);
  }

  91% {
    transform: scale(1, 1) rotate(0deg);
  }

  100% {
    transform: scale(1, 1) rotate(0deg);
  }
}

@keyframes box-3-anim {
  0% {
    transform: scale(1, 1) rotate(0deg);
    bottom: 0;
  }

  5% {
    bottom: 3rem;
  }

  15% {
    bottom: 0;
    transform: scale(1, 1) rotate(-180deg);
  }

  17.001% {
    bottom: 0;
    transform: translate(0.9rem, 0.6rem) scale(1.3, 0.6) translateY(0px) rotate(-180deg);
  }

  25% {
    bottom: 0;
    transform: scale(1, 1) rotate(-180deg);
  }

  56% {
    transform: scale(1, 1) rotate(-180deg);
    bottom: 0;
  }

  61% {
    bottom: 3rem;
  }

  71% {
    bottom: 0;
    transform: scale(1, 1) rotate(0deg);
  }

  73.001% {
    bottom: 0;
    transform: translate(-0.9rem, 0.6rem) scale(1.3, 0.6) translateY(0px) rotate(0deg);
  }

  81% {
    transform: scale(1, 1) rotate(0deg);
  }

  100% {
    transform: scale(1, 1) rotate(0deg);
  }
}

@keyframes box-4-anim {
  0% {
    transform: scale(1, 1) rotate(0deg);
    bottom: 0;
  }

  5% {
    bottom: 3rem;
  }

  15% {
    bottom: 0;
    transform: scale(1, 1) rotate(-180deg);
  }

  17.001% {
    bottom: 0;
    transform: translate(0.9rem, 0.6rem) scale(1.3, 0.6) translateY(0px) rotate(-180deg);
  }

  25% {
    bottom: 0;
    transform: scale(1, 1) rotate(-180deg);
  }

  45% {
    transform: scale(1, 1) rotate(-180deg);
    bottom: 0;
  }

  50% {
    bottom: 3rem;
  }

  60% {
    bottom: 0;
    transform: scale(1, 1) rotate(0deg);
  }

  62.001% {
    bottom: 0;
    transform: translate(-0.9rem, 0.6rem) scale(1.3, 0.6) translateY(0px) rotate(0deg);
  }

  70% {
    transform: scale(1, 1) rotate(0deg);
  }

  100% {
    transform: scale(1, 1) rotate(0deg);
  }
}

@keyframes box-5-anim {
  0% {
    transform: scale(1, 1) rotate(0deg);
    bottom: 0;
  }

  5% {
    bottom: 3rem;
  }

  15% {
    bottom: 0;
    transform: scale(1, 1) rotate(-180deg);
  }

  17.001% {
    bottom: 0;
    transform: translate(0.9rem, 0.6rem) scale(1.3, 0.6) translateY(0px) rotate(-180deg);
  }

  25% {
    bottom: 0;
    transform: scale(1, 1) rotate(-180deg);
  }

  33% {
    transform: scale(1, 1) rotate(-180deg);
    bottom: 0;
  }

  38% { 
    bottom: 3rem;
  }

  48% {
    bottom: 0;
    transform: scale(1, 1) rotate(0deg);
  }

  50.001% {
    bottom: 0;
    transform: translate(-0.9rem, 0.6rem) scale(1.3, 0.6) translateY(0px) rotate(0deg);
  }

  58% {
    transform: scale(1, 1) rotate(0deg);
  }

  100% {
    transform: scale(1, 1) rotate(0deg);
  }
}

@keyframes box-6-anim {
  0% {
    transform: scale(1, 1) rotate(0deg);
    bottom: 0;
  }

  5% {
    bottom: 3rem;
  }

  15% {
    bottom: 0;
    transform: scale(1, 1) rotate(-180deg);
  }

  17.001% {
    bottom: 0;
    transform: translate(0.9rem, 0.6rem) scale(1.3, 0.6) translateY(0px) rotate(-180deg);
  }

  25% {
    bottom: 0;
    transform: scale(1, 1) rotate(-180deg);
  }

  33% {
    transform: scale(1, 1) rotate(-180deg);
    bottom: 0;
  }

  38% {
    bottom: 3rem;
  }

  48% {
    bottom: 0;
    transform: scale(1, 1) rotate(0deg);
  }

  50.001% {
    bottom: 0;
    transform: translate(-0.9rem, 0.6rem) scale(1.3, 0.6) translateY(0px) rotate(0deg);
  }

  58% {
    transform: scale(1, 1) rotate(0deg);
  }

  100% {
    transform: scale(1, 1) rotate(0deg);
  }
}

/* ================ */

/* Mobile Header Css Start Here */
.bottom-nav-bar .canvas_btn {
  display: none;
  justify-content: end;
  padding: 0 10px 0 0px;
}

.bottom-nav-bar .right-menus .canvas_btn i {
  color: #fff;
  font-size: 25px;
}

.mobile_header {
  position: fixed;
  z-index: 2;
  right: 0;
  background: var(--first-color);
  width: 30%;
  height: 100% !important;
  display: block;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0px 0px 30px;
  transition: 0.8s;
  transform: translateX(120%);
  top: 0;
  z-index: 99999;
  padding-top: 40px;
  overflow-x: scroll !important;
}

.mobile-menu-drop-header .dropdown {
  color: var(--third-color);
  font-family: 'poppins-meduim';
}

.mobile-menu-drop-header label {
  color: var(--third-color);
  font-family: 'poppins-meduim';
}

.mobile_header.show {
  transform: translateX(0%);
  padding-left: 10px;
  padding-right: 10px;
  height: auto;
  overflow-x: scroll;
}
.mobile-responsive-heart-icon p {
  position: absolute !important;
  left: 16px;
  top: 10px !important;
}
.mobile-responsive-heart-icon a span i {
  padding-right: 12px;
  font-size: 25px;
}
.mobile_header .cancel {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile_menus .menu-items {
  border-bottom: 1px solid #dcdcdc99;
  padding: 5px 0px;
  padding-right: 160px;
}
.call-us-all-responsive span a {
  font-size: 14px !important;
}
.mobile_header ul.mobile_menus {
  padding: 0;
  margin: 0;
  list-style: none;
}
.mobile_menus li a {
  text-transform: uppercase !important;
}
.mobile_header ul.mobile_menus li.menu_items {
  margin: 10px 0;
}

.mobile_header ul.mobile_menus li.menu_items.menu_btn {
  margin-top: 30px;
}

.mobile_header ul.mobile_menus li.menu_items a {
  color: var(--second-color);
  font-size: 15px;
  font-weight: 500;
  position: relative;
  text-decoration: none;
}

.mobile_header ul.mobile_menus button.accordion-button {
  padding: 0;
  margin: 0;
  color: #fff;
  background: #000 !important;
  box-shadow: none;
  outline: none;
}

.mobile_header ul.mobile_menus .accordion-item {
  border: 0;
  background: transparent;
}

.mobile_header ul.mobile_menus .accordion-item h2 {
  line-height: unset;
}

.mobile_header ul.mobile_menus button.accordion-button::after {
  background-position-x: 20px;
  width: 40px;
  filter: brightness(0) invert(1);
  height: 40px;
  background-position-y: 15px;
}

.mobile_header ul.mobile_menus .accordion-item .accordion-body {
  padding: 0;
}

.mobile_header ul.mobile_menus .accordion-item .accordion-body ul {
  padding: 0;
}

.mobile_header ul.mobile_menus .accordion-item .accordion-body ul li {
  margin: 10px 0;
}

/* .mobile_header ul.mobile_menus li.menu_items.menu_btn a {
  background: #c98536;
  padding: 13px 25px;
  border-radius: 50px;
} */
.bottom-nav-bar .search a {
  color: transparent;
}

ul.mobile_menus li a {
  font-size: 17px;
  font-family: 'poppins-regular';
  text-decoration: unset;
  color: var(--third-color);
  font-weight: 100;
  text-transform: capitalize;
  line-height: 50px;
   text-decoration: uppercase;
}

.canvas_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
  display: none;
  padding-top: 20px;
}

.canvas_btn i {
  font-size: 25px;
}

.top-res-btn a {
  background-color: #ffffff;
  padding: 10px 30px;
  border-radius: 10px;
  color: var(--first-color);
  text-decoration: unset;
  font-family: 'poppins-meduim';
  text-transform: capitalize;
  line-height: 81px;
}

.lable-drop-down.mobile-menu-drop-header label {
  color: var(--third-color);
}

.canvas_btn img {
  width: 80%;
}
ul.mobile_menus li {
  border-bottom: 1px solid #ffffff6b;
}
@media (max-width: 1024px) {
  .bottom-nav-bar {
    display: none;
  }

  .middle-nav-bar {
    display: none;
  }

  .top-nav-sec {
    display: none;
  }

  .canvas_btn {
    display: flex;
  }
}

@media (max-width: 991px) {
  .bottom-nav-bar .canvas_btn {
    display: flex;
  }

}

@media (max-width: 768px) {
  .mobile_header {
    width: 40%;
  }
}

@media (max-width: 575px) {
  .mobile_header {
    width: 50%;
  }

.cart-img p {
  font-size: 13px !important;
  top: 0px !important;
  left: 8px !important;
  color: var(--third-color) !important;
}
}

@media (max-width: 480px) {
  .mobile_header {
    width: 100%;
  }
}
.mobile-menu-drop-header label {
  font-size: 20px !important;
  padding-bottom: 5px !important;
}
.responsive-flag-dropdown {
  padding: 10px 6px;
}
.mobile-menu-drop-header a {
  font-size: 14px !important;
}
.responsive-plant-img img {
  width: 80%;
  margin: 0 auto;
}
.mobile-responsive-cart-img a span i {
  padding-right: 20px;
}
.mobile-responsive-heart-icon span i {
  color: var(--third-color) !important;
}
.mobile-responsive-cart-img a span img {
  width: 9% !important;
}
.mobile-responsive-cart-img p {
  position: absolute;
  top: 8px;
  left: 7px;
}
.mobile-responsive-cart-img a span {
  color: var(--third-color);
  font-size: 20px;
  font-family: 'poppins-light';
  text-transform: uppercase;
}
.mobile-responsive-cart-img a span img {
  margin-right: 8px;
}

.mobile-responsive-heart-icon a span {
  color: var(--third-color);
  font-family: 'poppins-light';
}
/* Mobile Header Css Start Here */

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.box-loading {
  width: 21rem;
  height: 3rem;
  position: relative;
  margin: 0 auto;
}

.box-loading>.box {
  position: absolute;
  width: 3rem;
  height: 3rem;
  border-radius: 0.6rem;
  background-color: #7DAB3C;
  transform-origin: -0.75rem 1.5rem;
  box-shadow: 0 0 6px 2px rgba(40, 139, 171, 0.15);
}

.box-loading>.box:nth-child(1) {
  left: 0rem;
  background-color: #7DAB3C;
}

.box-loading>.box:nth-child(2) {
  left: 4.5rem;
  background-color: #7DAB3C;
  animation: box-2-anim 2s infinite linear;
  animation-delay: 0.22s;
}

.box-loading>.box:nth-child(3) {
  left: 9rem;
  background-color: #7DAB3C;
  animation: box-3-anim 2s infinite linear;
  animation-delay: 0.33s;
}

.box-loading>.box:nth-child(4) {
  left: 13.5rem;
  background-color: #7DAB3C;
  animation: box-4-anim 2s infinite linear;
  animation-delay: 0.44s;
}

.box-loading>.box:nth-child(5) {
  left: 17.5rem;
  background-color: #7DAB3C;
  animation: box-4-anim 2s infinite linear;
  animation-delay: 0.44s;
}

.box-loading>.box:nth-child(6) {
  left: 21rem;
  background-color: #7DAB3C;
  animation: box-5-anim 2s infinite linear;
  animation-delay: 0.55s;
}

.box-loading>.box:nth-child(1) {
  background-color: #7DAB3C;
  animation: moving 1s infinite cubic-bezier(0.6, 0, 0.4, 1) alternate;
}

.box-sahara p {
  font-size: 40px;
  color: var(--third-color);
  text-align: center;
  line-height: 52px;
  font-family: 'poppins-semibold';
}

/* ======================= */

/* Begin of Top Bar Header Css */
.delivery-dv p a {
  font-size: 11px;
  color: var(--sixth-color);
  font-family: 'poppins-regular';
  margin: 0;
  text-decoration: unset;
}

.delivery-dv p {
  margin: 0;
}

.call-us-now-dv h5 {
  font-size: 9px;
  color: var(--sixth-color);
  font-family: 'poppins-semibold';
  text-transform: uppercase;
  margin: 0;
}

.color-change a {
  color: var(--sixth-color);
  text-decoration: unset;
  font-family: poppins-meduim;
}

.top-nav-sec {
  background-color: var(--ninth-color);
  padding: 4px 0px;
}

.top-header-btn a {
  font-size: 11px;
  background-color: var(--first-color);
  color: var(--third-color);
  border: unset;
  width: 100%;
  border-radius: 6px;
  font-family: 'poppins-regular';
  padding: 3px 8px;
  text-decoration: unset;
}

.top-nav-sec .row {
  display: flex;
  align-items: baseline;
}

.call-us-now-dv {
  float: right;
  padding-right: 12px;
}

.call-and-btn {
  display: flex;
  align-items: center;
  justify-content: right;
}
.call-responsive {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.call-us-all-responsive h5 {
  font-size: 14px !important;
  color: var(--third-color);
  font-family: 'poppins-regular';
}
.top-header-btn {
  margin-top: -3px;
}

/* ========================= */

/* Begin of Middle  Navbar Css */
.dv-text p {
  font-size: 10px;
  font-family: "segoe-regular";
  margin: 0;
}

.dv-text p {
  font-size: 10px;
  color: var(--second-color);
  font-family: "segoe-regular";
  margin: 0;
}

.icon-text {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.responsive-middle-col {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 10px;
}
.responsive-middle-col  p {
  color: var(--third-color);
  font-family: 'poppins-light';
}
.responsive-middle-col  h5 {
  color: var(--third-color) !important;
  font-family: 'poppins-light' !important;
}
.responsive-middle-col i {
  color: var(--third-color);
  padding-right: 8px;
}


.dv-text h5 {
  font-size: 12px;
  color: var(--second-color);
  font-family: 'poppins-semibold';
  text-transform: uppercase;
  margin: 0;
}

.search-input input {
  width: 100%;
}

.search-input {
  position: relative;
}

.input-icon {
  position: absolute;
  top: 5px;
  left: 10px;
}

.search-input input {
  border: 1px solid #707070;
  padding: 5px 30px;
  border-radius: 7px;
  outline: none;
}

.input-icon i {
  color: #707070;
}

.lable-drop-down label {
  font-size: 12px;
  color: var(--second-color);
  font-family: 'segoe-regular';
  margin: 0;
}

.dv-for-drop-down a {
  font-size: 11px;
  color: var(--seond-color);
  font-family: 'poppins-semibold';
  text-decoration: unset;
}

.dv-for-drop-down .dropdown {
  line-height: 5px;
}

.dv-for-drop-down .dropdown-menu.show {
  font-size: 10px;
  display: block;
  border: unset;
  box-shadow: 0px 0px 1px #0003;
  line-height: 13px;
  color: var(--seveth-color);
  font-family: 'segoe-regular';
  text-transform: capitalize;
}

.all-stuff {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flag-dropdown button#dropdownMenu2 {
  padding: 0;
  border: none;
}

.flag-dropdown ul.dropdown-menu.show {
  min-width: 60px;
}

.middle-nav-bar .row {
  display: flex;
  align-items: center;
}

.heart-icon i {
  font-size: 22px;
}

.cart-img {
  position: relative;
}

.cart-img p {
  position: absolute;
  top: -7px;
  left: 8px;
  margin: 0;
  color: var(--sixth-color);
  font-family: 'segoe-semibold';
}

.cart-img span {
  font-size: 15px;
  color: var(--second-color);
  font-family: 'segoe-semibold';
  text-transform: capitalize;
}

.heart-icon {
  position: relative;
}

.heart-icon p {
  font-size: 10px;
  position: absolute;
  top: 0;
  right: -8px;
  margin: 0;
  background-color: var(--sixth-color);
  color: var(--third-color);
  border-radius: 13px;
  width: 15px;
  height: 15px;
  text-align: center;
  line-height: 13px;
  border: 1px solid #ffffff;
}

.cart-img a {
  text-decoration: unset;
}

.heart-icon a i {
  color: var(--sixth-color);
}

/* ================== */

/* Begin of Bottom Nav Css */
.nav-ul ul {
  display: flex;
  padding: 6px 0px;
  list-style: none;
  justify-content: flex-end;
  margin: 0;
}

.bottom-nav-bar {
  background-color: var(--sixth-color);
}

.all-ul-nav li a {
  font-size: 13px;
  color: var(--third-color);
  font-family: 'poppins-regular';
  text-decoration: unset;
  text-transform: uppercase;
}

.all-ul-nav li {
  padding-left: 30px;
}

.middle-nav-bar {
  padding-bottom: 0;
}

.all-select select {
  background-color: var(--first-color);
  border: unset;
  border-radius: unset;
  box-shadow: 0px 0px 0px 0px;
}

.all-select option {
  background-color: var;
  background-color: var(--third-color);
  border: 1px solid #7dab3c;
}

/* ==================== */

/* Begin of Pageheader section */
.content-wrapper h2 {
  font-size: 40px;
  color: var(--second-color);
  font-family: 'poppins-semibold';
  text-transform: capitalize;
}

.content-wrapper {
  text-align: center;
  padding-top: 70px;
}

/* =====================
 */
/* Begin of Main Banner Section */
.main-banner-sec {
  padding-top: 60px;
  position: relative;
  padding-bottom: 10px;
}

.third-banner-img {
  margin-top: 29px;
}

.banner-first-img img {
  width: 100%;
  position: relative;
  /* filter: brightness(0.6); */
}

.banner-first-img {
  position: relative;
}

.first-banner-text {
  position: absolute;
  top: 0;
  left: 39px;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 2;
}

.all-text h2 {
  font-size: 61px;
  color: var(--third-color);
  font-family: 'poppins-bold';
  margin: 0;
  text-transform: uppercase;
}

.all-text h3 {
  font-size: 23px;
  color: var(--third-color);
  font-family: 'poppins-meduim';
  margin: 0;
}

.all-text p {
  font-size: 15px;
  color: var(--third-color);
  font-family: 'poppins-regular';
  width: 490px;
  margin: 0;
  padding: 22px 0px;
}

.main-btn a {
  font-size: 12px;
  color: var(--sixth-color);
  background-color: var(--third-color);
  padding: 11px 30px;
  border-radius: 8px;
  font-family: 'segoe-semibold';
  text-decoration: unset;
}

.second-banner-text {
  position: absolute;
  bottom: 11px;
  left: 17px;
}

.second-all-text h4 {
  font-size: 22px;
  color: var(--third-color);
  font-family: 'poppins-bold';
  margin: 0;
}

.all-text h2 span {
  text-transform: capitalize;
}

.banner-first-img figure {
  position: relative;
}

.firts-banner-img figure::before {
  content: '';
  background-image: linear-gradient(to right, #0000009e, #06060678, transparent, transparent, transparent);
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0px;
  border-radius: 15px;
  z-index: 1;
}

/* ======================= */

/* Begin of Icons Section Css */
.my-text h5 {
  font-size: 12px;
  color: var(--second-color);
  font-family: 'poppins-bold';
  margin: 0;
  text-transform: capitalize;
}

.my-text p {
  font-size: 12px;
  color: var(--tenth-color);
  font-family: 'poppins-meduim';
  margin: 0;
}

.icon-with-text {
  display: flex;
}

.my-text {
  padding-left: 18px;
}

.differnt-sec {
  padding: 30px 0px;
}

.all-icons-in-one-dv {
  display: flex;
  justify-content: space-between;
}

/* ======================== */

/* Begin of Top Categories Css */
.top-categories-sec h5 {
  font-size: 16px;
  color: var(--eleventh-color);
  font-family: 'open-sans-bold';
  margin: 0;
  background-color: var(--third-color);
  padding: 14px 30px;
  border-radius: 5px;
  box-shadow: 0px 3px 0px 0px #70707061;
  text-transform: capitalize;
}

.top-categories-sec {
  width: 76%;
  text-align: center;
}

.top-cat-img {
  position: relative;
  padding-top: 20px;
}

.text-for-movement-in-categories {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.top-categories-sec-all {
  background-color: var(--back-color);
  padding: 70px 0px;
}

.top-categories-head h4 {
  font-size: 22px;
  color: var(--second-color);
  font-family: 'poppins-meduim';
  margin: 0;
  text-transform: capitalize;
}

.top-categories-head {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #707070;
  padding-bottom: 7px;
}

.categories-main-img {
  height: 294px;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
}
.categories-main-img img.img-fluid {
  height: 275px;
  width: 95%;
  object-fit: cover;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
  margin: 0 auto;
}

.categories-main-img:hover img.img-fluid {
  transform: scale(1.1);
}

/* ========================= */

/* Begin of Slider Css */
.slide-star-ul {
  padding: 0;
  list-style: none;
  display: flex;
  margin: 0;
}

.slide-text p {
  font-size: 14px;
  color: var(--second-color);
  font-family: 'poppins-semibold';
  margin: 0;
  padding: 10px 0px;
  letter-spacing: 1px;
}

.slide-for-flex h5 {
  font-size: 15px;
  color: var(--first-color);
  font-family: 'poppins-bold';
  margin: 0;
}

.slide-star-ul li i {
  color: var(--fifth-color);
}

.slide-for-flex {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.slide-dv img {
  width: 100%;
  box-shadow: 0px -3px 0px #0000000d;
  border-radius: 10px 10px 0px 0px;
}

.slide-dv {
  background-color: var(--ninth-color);
  padding: 17px;
  margin: 6px;
  border-radius: 15px;
  box-shadow: 1px 6px 10px -2px #00000033;
}

.slide-sec button.slick-arrow.slick-prev {
  display: none !important;
}

.prdocut-slide-sec button.slick-arrow.slick-prev {
  font-size: 0;
  padding: 19px 19px;
  background-color: #5BBA41;
  border: none;
  border-radius: 50px;
  position: absolute;
  top: -77px;
  right: 47px;
}

.prdocut-slide-sec button.slick-arrow.slick-next {
  font-size: 0;
  padding: 19px 19px;
  background-color: #5BBA41;
  border: none;
  border-radius: 50px;
  position: absolute;
  top: -77px;
  right: 0;
}

.prdocut-slide-sec {
  position: relative;
  padding-bottom: 40px;
}

.slide-sec button.slick-arrow.slick-next {
  display: none !important;
}

.slide-head {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #DEDEDE;
  padding-bottom: 6px;
  margin-bottom: 30px;
  padding-bottom: 12px;
  position: relative;
}

.slide-head h4 {
  font-size: 25px;
  color: var(--sixth-color);
  font-family: 'poppins-semibold';
  margin: 0;
  text-transform: capitalize;
}

.slide-sec {
  padding: 60px 0px;
}

.slide-head h4 a {
  font-size: 18px;
  color: var(--second-color);
  text-decoration: unset;
}

/* =================== */

/* Begin of Blogmain Css */
.blog-compo-text-date h5 {
  font-size: 11px;
  color: var(--second-color);
  font-family: 'poppins-regular';
  margin: 0;
}

.blog-compo-text-date {
  display: flex;
  justify-content: space-between;
  background-color: #00000017;
  padding: 10px 16px;
}

.blog-compo-text-date h5 span {
  border-left: 1px solid #000000;
  padding: 0px 8px;
}

.only-blog-text h4 {
  font-size: 16px;
  color: var(--first-color);
  font-family: 'poppins-semibold';
  margin: 0;
  padding-bottom: 8px;
}

.only-blog-text p {
  font-size: 12px;
  color: var(--seveth-color);
  font-family: 'poppins-regular';
  margin: 0;
}

.only-blog-text {
  padding: 16px;
  background-color: #F9F9F9;
}

.blog-main-btn {
  background-color: var(--first-color);
  text-align: center;
  border-radius: 0px 0px 20px 20px;
  padding: 10px 5px;
}

.blog-main-btn a {
  font-size: 14px;
  color: var(--third-color);
  font-family: 'poppins-meduim';
  text-transform: capitalize;
  text-decoration: unset;
}

.blog-main-sec {
  padding-bottom: 40px;
}

.blog-compo-img img {
  width: 100%;
  border-radius: 20px 20px 0px 0px;
}

.blog-compo-all-dv {
  margin: 10px 0px;
  border: 1px solid #dcdcdc;
  border-radius: 20px;
  box-shadow: 0px 0px 5px #00000026;
}

.blogs-head h5 {
  font-size: 28px;
  color: var(--second-color);
  font-family: 'poppins-meduim';
  margin: 0;
}

.blogs-head p {
  font-size: 14px;
  color: #0000006e;
  font-family: 'poppins-regular';
  width: 65%;
  margin: 10px auto;
}

.blogs-head {
  text-align: center;
  padding: 14px 0px;
}

.blog-sec {
  padding-bottom: 70px;
}

/* ====================== */

/* Begin of Newsletter Css */
.newsletter-dv h3 {
  font-size: 55px;
  color: var(--second-color);
  font-family: 'poppins-semibold';
  margin: 0;
  padding-bottom: 2px;
}

.newsletter-dv p {
  font-size: 14px;
  color: var(--seveth-color);
  font-family: 'poppins-regular';
  margin: 0;
  padding-bottom: 15px;
}

.news-letter-input input {
  width: 75%;
  padding: 12px;
  box-shadow: 0px 0px 11px 4px #0000000d;
  border: unset;
  border-radius: 8px 0px 0px 8px;
  outline: none;
}
.news-letter-sec {
  padding: 100px 0px;
}

.news-letter-input {
  position: relative;
  box-shadow: 0px 2px 4px #0000003d;
  border-radius: 10px;
}

.newsletter-btn button {
  font-size: 14px;
  font-family: 'poppins-semibold';
  background-color: var(--first-color);
  border: none;
  padding: 14px 40px;
  color: var(--third-color);
  border-radius: 0px 8px 9px 0px;
}

.newsletter-btn {
  position: absolute;
  top: 0;
  right: 0;
}

.newsletter-dv {
  text-align: center;
}

/* .newsletter-img img {
  width: 100%;
  height: 190px;
} */
.newsletter-img img {
  width: 100%;
  height: auto;
}
.newsletter-img-for-move img {
  position: relative;
  top: 32px;
}
.news-letter-sec .row {
  display: flex;
  align-items: center;
}

.Newsletter-all {
  background-color: var(--ninth-color);
  box-shadow: 1px 3px 2px 0px #00000026;
  border-radius: 20px;
  padding: 10px 30px;
  padding-bottom: 0;
}

.news-letter-input input::placeholder {
  font-size: 12px;
  color: #898989;
  font-family: 'poppins-regular';
  padding: 15px;
}

/* ====================== */
/* Begin of Organic Product Css */
.organic-food {
  position: relative;
  box-shadow: 0px 3px 1px #0000001f;
  margin: 20px 0px;
  height: 280px;
}

.organic-food img {
  height: 184px;
  object-fit: cover;
}

.product-heading {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
}

.pro-head h5 {
  font-size: 15px;
  color: var(--second-color);
  font-family: 'poppins-bold';
  margin: 0;
  line-height: 32px;
  font-weight: 800;
}

.customize-product-head h5 {
  font-size: 14px;
  line-height: 19px;
  padding-top: 5px;
}
/* .pro-head {
  background-color: #efeff7;
  width: 132px;
  height: 32px;
  margin: 0 auto;
  border-radius: 0px 0px 20px 20px;
  text-align: center;
  box-shadow: 0px 1px 0px #0000001a;
} */
.pro-head {
  background-color: #efeff7;
  width: 138px;
  height: 38px;
  margin: 0 auto;
  border-radius: 0px 0px 25px 25px;
  text-align: center;
  box-shadow: 0px 3px 1px #0000001f;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customize-product-head {
  background-color: #efeff7;
  width: 160px;
  height: 46px;
  margin: 0 auto;
  border-radius: 0px 0px 28px 28px;
  text-align: center;
  box-shadow: 0;
}

.for-back-color-and-raduis {
  background-color: var(--first-color);
  border-radius: 16px 0px 0px 16px;
  position: relative;
}

.organic-img img {
  width: 90%;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
}

.background-head h3 {
  font-size: 36px;
  color: var(--third-color);
  font-family: 'poppins-semibold';
}

.background-head {
  text-align: center;
  padding: 53px;
  padding-bottom: 0;
}

.oraganic-food-sec {
  padding: 80px 0px;
}

.oraganic-food-sec .container {
  border: 1px solid #7dab3c;
  border-radius: 20px;
}

.all-class-for-padding {
  padding: 16px 16px;
}

.slide-dv.organic-food figure::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  background: #7dab3c47;
  top: 0px;
  transform: translateY(100%);
  transition: all 0.4s ease-in-out;
}

.slide-dv.organic-food figure {
  position: relative;
  overflow: hidden;
}

.slide-dv.organic-food:hover figure::after {
  transform: translateY(0%);
}


/* ============== */

/* Begin of Fashion Accessories */
.for-back-red {
  background-color: #F90101;
  border-radius: 20px 0px 0px 16px;
  position: relative;
}

.fashion-accessories .container {
  border: 1px solid #f90101;
}

.for-fashion-accessories h5 {
  font-size: 13px;
  line-height: 16px;
}

.for-fashion-accessories {
  padding-top: 2px;
}

.fashion-dv figure::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  background: #f901014d !important;
  top: 0px;
  transform: translateY(100%);
  transition: all 0.4s ease-in-out;
}

.fashion-dv figure {
  position: relative;
  overflow: hidden;
}

.fashion-dv:hover figure::after {
  transform: translateY(0%);
}

.oraganic-food-sec a {
  text-decoration: unset;
}

/* ============== */

/* Begin of Arts and Crafts */
.for-back-blue {
  background-color: var(--eigth-color);
  border-radius: 18px 0px 0px 18px;
  position: relative;
}

.arts-and-craft .container {
  border: 1px solid #49a6a4;
}

/* .organic-food img {
  height: 184px;
  object-fit: contain;
  background-color: var(--third-color);
  padding: 25px;
  padding-bottom: 0;
  box-shadow: 0px -3px 16px #0000000d;
} */
.organic-food img {
  height: 184px;
  object-fit: contain;
  background-color: var(--third-color);
  padding: 25px;
  padding-bottom: 0;
  box-shadow: 0px 3px 8px #00000029 inset;
}

.air-craft-img img {
  width: 100%;
  position: absolute;
  left: 0;
}

.customizable-home-img img {
  width: 60%;
  position: absolute;
  left: 80px;
}

/* .air-craft-img {
  position: absolute;
  bottom: 0;
  left: 0;
} */
.air-craft-dv figure::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  background: #49a6a442 !important;
  top: 0px;
  transform: translateY(100%);
  transition: all 0.4s ease-in-out;
}

.air-craft-dv figure {
  position: relative;
  overflow: hidden;
}

.air-craft-dv:hover figure::after {
  transform: translateY(0%);
}

/* =================== */

/* Begin of Customizable Css */
.for-back-yellow {
  background-color: var(--fifth-color);
  border-radius: 20px 0px 0px 20px;
  position: relative;
}

.customizable-home-sec .container {
  border: 1px solid #f6bf02;
}

.customizable-dv figure::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  background: #f6bf0247 !important;
  top: 0px;
  transform: translateY(100%);
  transition: all 0.4s ease-in-out;
}

.customizable-dv figure {
  position: relative;
  overflow: hidden;
}

.customizable-dv:hover figure::after {
  transform: translateY(0%);
}

/* =================== */


/* Begin of Footer Css */
.footer-sec {
  background-color: var(--second-color);
  padding-top: 70px;
}

.footer-text p {
  font-size: 12px;
  color: var(--ninth-color);
  font-family: 'poppins-regular';
  margin: 0;
}

.footer-head h4 {
  font-size: 23px;
  color: var(--ninth-color);
  font-family: 'poppins-meduim';
  margin: 0;
}

.footer-all ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

.footer-all ul li a {
  font-size: 11px;
  color: var(--ninth-color);
  font-family: "poppins-light";
  text-decoration: unset;
  font-weight: 100;
  text-transform: capitalize;
}

.footer-address span p {
  font-size: 12px;
  color: var(--ninth-color);
  font-family: 'poppins-light';
  margin: 0;
}

.footer-address span p a {
  font-size: 13px;
  color: var(--ninth-color);
  font-family: 'poppins-light';
  text-decoration: unset;
}

.footer-address span i {
  color: var(--ninth-color);
  padding-right: 14px;
}

.footer-address {
  display: flex;
  align-items: flex-start;
  padding: 9px 7px;
}

.footer-social-link ul {
  display: flex;
  margin: 0;
  text-align: center;
}

.footer-social-link ul li {
  padding-right: 0px;
  background-color: var(--ninth-color);
  margin-right: 18px;
  border-radius: 30px;
  width: 30px;
  height: 30px;
}

.footer-social-link ul li a i {
  color: var(--second-color);
  line-height: 31px;
}

.footer-head {
  padding-bottom: 16px;
  padding-top: 15px;
}

.footer-logo img {
  width: 100%;
}

.footer-all.widget2 {
  padding-left: 48px;
}

.footer-social-link ul {
  padding: 18px 4px;
}

.copy-right p {
  font-size: 12px;
  color: var(--ninth-color);
  font-family: 'poppins-regular';
  margin: 0;
  padding-top: 10px;
  padding-bottom: 5px;
}

.copy-right {
  border-top: 1px solid #ffffff1a;
  text-align: center;
  margin: 0;
}

/* ================= */
.bottom-nav-bar .dropdown-menu.show {
  display: block;
  border: 1px solid #7dab3c !important;
  border-radius: 0px 0px 30px 30px !important;
  margin-top: -4px !important;
  width: 100% !important;
}

.bottom-nav-bar ul.dropdown-menu {
  border: 1px solid #7dab3c !important;
  border-radius: 0px 0px 30px 30px !important;
  margin-top: -4px !important;
  width: 100% !important;
}

.bottom-nav-bar .dropdown ul.dropdown-menu li {
  display: block;
  padding: 4px 0;
  text-align: left;
  position: relative;
  border-bottom: 1px solid #70707012;
  padding: 5px;
  white-space: nowrap;
}

.bottom-nav-bar .dropdown ul.dropdown-menu li {
  display: block;
  padding: 4px 0;
  text-align: left;
  position: relative;
  border-bottom: 1px solid #70707012;
  padding: 5px;
  white-space: nowrap;
}

.bottom-nav-bar .dropdown:hover .dropdown-menu {
  display: block !important;
}

.bottom-nav-bar .dropdown ul.dropdown-menu li.inner-dropdown:hover ul.inner-dropdown-menu {
  opacity: 1;
}

.bottom-nav-bar .dropdown ul.dropdown-menu li.inner-dropdown ul.inner-dropdown-menu {
  position: absolute;
  right: -105% !important;
  background: var(--third-color);
  width: 270px;
  opacity: 0;
  transition: 0.6s;
  top: 0;
}

.bottom-nav-bar .dropdown-item.sherwood-dropdown a {
  padding: 7px 7px;
  font-size: 12px;
  color: var(--second-color) !important;
  font-family: 'poppins-semibold';
  text-decoration: unset;
  text-transform: capitalize;
}

.bottom-nav-bar .dropdown button {
  font-size: 15px;
  font-family: 'poppins-meduim';
  background-color: #7DAB3C !important;
  width: 100% !important;
  border-radius: 0px;
}

.bottom-nav-bar .dropdown-item.sherwood-dropdown {
  padding: 0px 4px;
}

.bottom-nav-bar .inner-dropdown-menu.for-position-up li a {
  font-size: 14px;
  color: var(--second-color);
  font-family: 'poppins-meduim';
  text-decoration: unset;
}

.bottom-nav-bar .inner-dropdown-menu.for-position-up li {
  border-bottom: none;
}

.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--first-color);
  border-color: var(--bs-pagination-hover-border-color);
  color: var(--third-color) !IMPORTANT;
}

/* ================== */

/* Begin of Fabric Css */
.small-slider h4 {
  font-size: 14px;
  color: var(--second-color);
  font-family: 'poppins-meduim';
  margin: 0;
}

/* ================ */

/* Begin of Other Css */
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
  border-radius: 20px;
}

/* ================= */
@media (max-width: 1600px) {
  .bottom-nav-bar .dropdown ul.dropdown-menu li.inner-dropdown ul.inner-dropdown-menu {
    right: -89% !important;
  }
}

/* Begin of Responsive Section */
@media (max-width: 1920px) {}

@media (max-width: 1600px) {}

@media (max-width: 1440px) {}

@media (max-width: 1366px) {
  .top-categories-sec h5 {
    font-size: 14px;
    padding: 14px 14px;

  }
  .slide-text p {
    font-size: 12px;

}
}

@media (max-width: 1280px) {}

@media (max-width: 1100px) {
  .delivery-dv p a {
    font-size: 8px;
  }

  .call-us-now-dv h5 {
    font-size: 8px;
  }

  .cart-img span {
    font-size: 12px;
  }

  .lable-drop-down label {
    font-size: 9px;
  }

  .dv-for-drop-down a {
    font-size: 9px;
  }

  .heart-icon i {
    font-size: 17px;
  }

  .cart-img a span img {
    width: 30%;
  }

  .cart-img p {
    font-size: 13px;
    top: -3px;
    left: 5px;
  }

  .my-text h5 {
    font-size: 9px;
  }

  .my-text p {
    font-size: 10px;
  }

  .top-categories-sec h5 {
    font-size: 12px;
    padding: 14px 18px;
  }

  .slide-dv {
    padding: 17px 7px;
  }

  .slide-text p {
    font-size: 10px;
  }

  .slide-for-flex h5 {
    font-size: 12px;
  }

  .slide-star-ul li i {
    font-size: 12px;
  }

  .pro-head {
    width: 124px;
    height: 31px;
  }

  .background-head {
    padding: 53px 0px;
  }

  .pro-head h5 {
    font-size: 9px;
    line-height: 25px;
  }

  .organic-food {
    height: 270px;
  }

  .blog-compo-text-date h5 {
    font-size: 8px;
  }

  .only-blog-text h4 {
    font-size: 12px;
  }

  .only-blog-text p {
    font-size: 9px;
  }

  .blog-main-btn {
    padding: 7px 5px;
  }

  .blog-main-btn a {
    font-size: 12px;
  }

  .news-letter-sec {
    padding: 70px 0px;
  }
}

@media (max-width: 1024px) {
  .all-text h2 {
    font-size: 41px;
  }

  .all-text h3 {
    font-size: 18px;
  }

  .all-text p {
    font-size: 13px;
  }

  .second-all-text h4 {
    font-size: 18px;
  }
  .header-shop-by-category {
    display: block;
}
}

@media (max-width: 991px) {
  .all-text h2 {
    font-size: 31px;
  }

  .all-text h3 {
    font-size: 12px;
  }

  .all-text p {
    font-size: 10px;
    width: 78%;
  }

  .second-all-text h4 {
    font-size: 12px;
  }

  .third-banner-img {
    margin-top: 25px;
  }

  .main-btn a {
    font-size: 10px;
    padding: 9px 26px;
  }

  .my-text {
    padding-left: 9px;
  }

  .my-text p {
    font-size: 8px;
  }

  .my-text h5 {
    font-size: 8px;
    white-space: nowrap;
  }

  .top-categories-head h4 {
    font-size: 15px;
  }

  .slide-head h4 {
    font-size: 17px;
  }

  .slide-head h4 a {
    font-size: 14px;
  }

  .organic-food img {
    height: 114px;
    padding: 12px;
  }

  .organic-food {
    height: 185px;
  }

  .slide-text p {
    font-size: 8px;
  }

  .background-head h3 {
    font-size: 16px;
  }

  .pro-head h5 {
    font-size: 6px;
    line-height: 25px;
  }

  .pro-head {
    width: 104px;
    height: 27px;
  }

  .slide-for-flex h5 {
    font-size: 9px;
  }

  .slide-star-ul li i {
    font-size: 9px;
  }

  .slide-text p {
    font-size: 8px;
    padding-bottom: 0;
  }

  .blog-compo-text-date h5 {
    font-size: 7px;
  }

  .only-blog-text {
    padding: 16px 7px;
  }

  .only-blog-text p {
    font-size: 7px;
  }

  .only-blog-text h4 {
    font-size: 9px;
  }

  .blog-compo-text-date {
    padding: 10px 4px;
  }

  .blog-main-btn {
    padding: 1px 5px;
  }

  .blog-main-btn a {
    font-size: 10px;
  }

  .newsletter-dv h3 {
    font-size: 30px;
  }

  .newsletter-dv p {
    font-size: 9px;
  }

  .newsletter-btn button {
    font-size: 10px;
    padding: 17px 17px;
  }

  .oraganic-food-sec {
    padding: 30px 0px;
  }

  .Newsletter-all {
    padding: 10px 7px;
  }

  .newsletter-img img {
    height: 142px;
  }

  .footer-text p {
    font-size: 9px;
  }

  .footer-head h4 {
    font-size: 16px;
  }

  .main-banner-sec {
    padding-top: 20px;
  }

  .footer-all ul li a {
    font-size: 7px;
  }

  .footer-address span p {
    font-size: 7px;
  }

  .footer-all.widget2 {
    padding-left: 18px;
  }

  .footer-address span p a {
    font-size: 8px;
  }

  .footer-address {
    align-items: center;
  }

  .footer-social-link ul li {
    margin-right: 4px;
  }

  .footer-social-link ul li a i {
    font-size: 14px;
  }

  .copy-right p {
    font-size: 9px;
  }
}

@media (max-width: 820px) {}

@media (max-width: 768px) {}

@media (max-width: 575px) {
  .all-text h2 {
    font-size: 20px;
  }

  .all-text p {
    font-size: 8px;
    width: 78%;
  }

  .first-banner-text {
    left: 19px;
  }

  .all-text p {

    padding: 12px 0px;
  }

  .firts-banner-img figure::before {
    border-radius: 7px;

  }

  .second-banner-img {
    margin-top: 25px;
  }

  .all-icons-in-one-dv {
    display: block;
  }

  .my-text h5 {
    font-size: 16px;
  }

  .my-text p {
    font-size: 16px;
  }

  .icon-with-text {
    padding: 10px 5px;
  }

  .for-back-color-and-raduis {
    border-radius: 16px;
    height: 400px;
  }

  .organic-img img {
    width: 70%;
  }

  .slide-text p {
    font-size: 13px;
  }

  .slide-for-flex h5 {
    font-size: 13px;
  }

  .slide-star-ul li i {
    font-size: 13px;
  }

  .top-categories-sec-all {
    padding: 20px 0px;
  }

  .organic-food {
    height: 317px;
  }

  .organic-food img {
    height: 234px;
    padding: 0px;
    padding-top: 40px;
}
  .pro-head {
    width: 184px;
    height: 38px;
}

  .for-back-red {
    border-radius: 16px;
    height: 400px;
  }

  .pro-head h5 {
    font-size: 17px;
    line-height: 16px;
}

  .for-back-yellow {
    border-radius: 16px;
    height: 400px;
  }

  .for-back-blue {
    border-radius: 16px;
    height: 400px;
  }

  .customizable-home-img img {
    width: 37% !important;
    position: absolute;
    left: 97px !important;
  }

  .air-craft-img img {
    width: 100% !important;
    position: absolute;
    left: 0 !important;
  }

  .organic-img img {
    left: 40px;
  }

  .news-letter-sec {
    padding-top: 0;
  }

  .newsletter-img img {
    height: auto;
}
.newsletter-dv {
  text-align: center;
  padding-top: 20px;
}
  .news-all {
    padding: 20px 0px;
  }
  .my-text {
    padding-left: 9px;
    min-width: 327px;
}

  .footer-sec {
    padding-top: 10px;
  }

  .footer-all.widget2 {
    padding-left: 0px;
  }

  .footer-all ul li a {
    font-size: 12px;
  }

  .footer-address span p {
    font-size: 12px;
  }

  .footer-address span p a {
    font-size: 13px;
  }

  .footer-head h4 {
    font-size: 23px;
  }

  .content-wrapper {
    text-align: center;
    padding-top: 30px;
  }

  .box-sahara p {
    font-size: 16px;
  }
  .organic-food img {
    box-shadow: 0px 1px 0px #00000012 inset;
}
.background-head h3 {
  font-size: 23px;
}
.oraganic-food-sec {
  padding: 30px 5px;
}
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  position: absolute;
}
}

/* ===================== */