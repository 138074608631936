.seller-dv {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
}
.seller-store-all {
    background-color: var(--first-color);
    padding: 35px;
    border-radius: 20px;
}
.small-heading h5 {
    font-size: 24px;
    color: var(--third-color);
    font-family: 'poppins-semibold';
    margin: 0;
    padding: 6px 0px;
}
.icon-number i {
    color: var(--third-color);
    line-height: 40px;
    padding-right: 10px;
}
.icon-number a {
    font-size: 15px;
    color: var(--third-color);
    font-family: 'poppins-regular';
    margin: 0;
    text-decoration: unset;
}
.seller-stars ul {
    padding: 0;
    list-style: none;
    display: flex;
    margin: 0;
}
.seller-stars ul li {
    color: var(--third-color);
    padding-right: 2px;
}
.seller-stars {
    padding-top: 10px;
}
.seller-logo-img img {
    width: 100%;
    border-radius: 20px;
}
.seller-about h3 {
    font-size: 24px;
    color: var(--third-color);
    font-family: 'poppins-semibold';
    margin: 0;
    padding-bottom: 10px;
}
.seller-store-sec .row {
    display: flex;
    align-items: flex-start;
}
.seller-names-and-icons {
    padding-top: 15px;
}
.seller-about p {
    font-size: 14px;
    color: var(--third-color);
    font-family: 'poppins-regular';
    margin: 0;
    font-weight: 100;
}
.seller-about {
    padding-left: 130px;
    padding-top: 15px;
    padding-right: 55px;
}

.seller-tabs-section .nav-link {
    display: block;
    padding: 10px 36px;
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: var(--first-color);
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
    border: 1px solid;
}
.seller-tabs-section {
    padding: 50px 0px;
}

.seller-tabs-section .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    font-family: 'poppins-regular';
    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--first-color);
}
.seller-tabs-section .nav-item {
    margin-right: 20px;
}

.all-nav-flex-stuff {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.seller-search input {
    padding: 5px 50px;
    border: 1px solid #C2C2C2;
    border-radius: 6px;
    outline: none;
}
.seller-search {
    position: relative;
}
.seller-search-icon {
    position: absolute;
    top: 7px;
    left: 10px;
}
.seller-search-icon i {
    color: #C2C2C2;
    border-right: 1px solid #c2c2c2;
    padding-right: 12px;
    outline: none;
}
.seller-search input::placeholder {
    font-size: 13px;
    color: var(--second-color);
    font-family: 'poppins-regular';
}
.seller-sort-flex {
    padding-top: 50px;
    padding-bottom: 6px;
}
.sellerstore-review {
    background-color: #F2F2F2;
    border-radius: 18px;
    padding: 26px 10px;
    border: unset;
}
.reviews-seller-store {
    padding: 30px 0px;
}
.review-rating-star ul {
    padding: 0;
    list-style: none;
    display: flex;
    margin: 0;
    padding-left: 20px;
}
.review-rating-star h4 {
    font-size: 22px;
    color: var(--second-color);
    font-family: 'poppins-bold';
    margin: 0;
}
.review-rating-star {
    display: flex;
    padding: 30px 0px;
}
.review-head h3 {
    font-size: 35px;
    color: var(--first-color);
    font-family: 'poppins-regular';
}
.review-head p {
    font-size: 12px;
    color: #AEAEAE;
    font-family: 'poppins-regular';
    margin: 5px 0px;
}
.review-form-input input {
    border: 1px solid #D1D1D1;
    width: 100%;
    padding: 10px 30px;
    margin: 20px 0px;
    outline: none;
}
.review-form-input textarea {
    height: 150px;
    border-radius: unset;
    resize: none;
    padding: 20px 30px;
}
.review-send button {
    background-color: var(--first-color);
    width: 30%;
    padding: 10px;
    border: unset;
    font-size: 17px;
    color: var(--third-color);
    font-family: 'poppins-regular';
    text-transform: capitalize;
    letter-spacing: 1px;
    border-radius: 7px;
}
.review-form-input input::placeholder {
    font-size: 14px;
    color: #BFBFBF;
    font-family: 'poppins-regular';
}
.review-form-input textarea::placeholder {
    font-size: 14px;
    color: #BFBFBF;
    font-family: 'poppins-regular';
}
.seller-review-form-sec {
    width: 50%;
    padding: 20px 0px;
}

/* Begin of Responsive Section */
@media (max-width: 1920px) {

}
@media (max-width: 1600px) {

}
@media (max-width: 1440px) {

}
@media (max-width: 1366px) {

}
@media (max-width: 1280px) {

}
@media (max-width: 1100px) {
    .seller-logo-img {
        margin-right: 6px;
    }
    .small-heading h5 {
        font-size: 19px;
    }
}
@media (max-width: 1024px) {
 

}
@media (max-width: 991px) {
    .seller-about {
        padding-left: 0px;
    }
    .seller-store-all {
         padding: 35px 15px;
       
    }
    .seller-about p {
        font-size: 10px;
       
    }.seller-logo-img img {
        width: 80%;
        border-radius: 20px;
    }
    .icon-number a {
        font-size: 11px;
    }

}
@media (max-width: 820px) {

}
@media (max-width: 768px) {

}
@media (max-width: 575px) {
    .all-nav-flex-stuff {
        display: block;
    }
    .seller-dv {
        display: block;
    }
    .seller-logo-img img {
        width: 100%;
        border-radius: 20px;
    }
    .seller-review-form-sec {
        width: 100%;
        padding: 20px 0px;
    }
    .seller-tabs-section {
        padding-top: 25px;
        padding-bottom: 0;
    }
    .review-form-input input {
        margin: 0;
        margin-bottom: 20px;
    }
    .review-head p {
        margin-bottom: 10px;
    }
    .review-send button {
        width: 50%;
    }
    .reviews-seller-store {
        padding: 30px 12px;
    }
}
/* ===================== */