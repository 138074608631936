.show-my-profile p {
    font-size: 14px;
    color: var(--second-color);
    font-family: 'poppins-regular';
    margin: 0;
}
.show-my-profile span i {
    color: var(--first-color);
    min-width: 25px;
}
.show-my-profile {
    display: flex;
    align-items: baseline;
    padding: 10px 0px;
    border-bottom: 1px solid #70707033;
}
.show-profile {
    background-color: var(--ninth-color);
    padding: 20px 40px;
    border-radius: 20px;
    box-shadow: 0px 0px 7px #dcdcdc;
}
.profile-edit-btn a {
    font-size: 15px;
    color: var(--third-color);
    background-color: var(--first-color);
    padding: 10px 38px;
    border-radius: 7px;
    font-family: 'poppins-regular';
    text-decoration: unset;
}
.my-profile-all {
    padding: 30px 0px;
    padding-top: 20px;
}
.no-border-for-profile {
    border-bottom: none;
}
.show-my-profile img {
    width: 100%;
   
}
.no-border-for-profile .for-align {
    padding-right: 7px;
}

/* Begin of Edit Profile */
.edit-profile-input input {
    width: 100%;
    padding: 7px 38px;
    border: none;
    margin: 7px 0px;
    outline: none;
    border-radius: 6px;
    box-shadow: 0px 3px 12px #00000047;
}
.all-edit-screen {
    background-color: var(--ninth-color);
    padding: 30px 30px;
    border-radius: 15px;
}
.edit-profile-input {
    position: relative;
}
.icon-of-edit-profile i {
    color: var(--first-color);
}
.icon-of-edit-profile {
    position: absolute;
    top: 14px;
    left: 10px;
}
.edit-profile-input input::placeholder {
    font-size: 14px;
    color: #6C757D;
    font-family: 'poppins-regular';
    text-transform: capitalize;
}
.icon-eye {
    position: absolute;
    top: 15px;
    right: 16px;
}
.icon-eye i {
    color: #B5B5B5;
}
/* ========================= */

/* Begin of Change Password */
.all-edit-screen label {
    font-size: 14px;
    color: var(--second-color);
    font-family: 'poppins-meduim';
}
/* ===================== */

/* Begin of My Orders Css */
.all-table-order table {
    width: 100%;
}
.all-table-order th {
    font-size: 15px;
    color: var(--third-color);
    font-family: 'segoe-regular';
    text-align: center;
    font-weight: 100;
    padding: 8px;
}
.all-table-order td {
    text-align: center;
}
.all-table-order td {
    text-align: center;
    font-size: 12px;
    color: #2A263D;
    font-family: 'poppins-regular';
    margin: 0;
    padding: 15px 0px;
}
.all-table-order {
    background-color: var(--back-color);
    padding: 20px 12px;
    border-radius: 10px;
}
.all-table-order tr {
    background-color: var(--third-color);
    box-shadow: 0px 3px 2px #00000017;
    border-radius: 20px;
}
.order-td-left {
    border-radius: 10px 0px 0px 10px  !important;
}
.end-td-borde-raduis {
    border-radius: 0px 10px 10px 0px  !important;
}
.all-table-order td button {
    font-size: 12px;
    background-color: var(--first-color);
    color: var(--third-color);
    font-family: 'poppins-regular';
    border: none;
    padding: 4px 15px;
    border-radius: 7px;
    box-shadow: 0px 4px 4px #00000036;
}
.all-table-order thead {
    font-size: 13px;
    background: var(--first-color);
  
}
.for-different-color {
    color: var(--first-color);
    font-family: 'poppins-meduim';
}

/* =================== */

/* Begin of Sidebar Css */
.sidebar-img {
    background-color: var(--first-color);
    border-radius: 15px;
    position: relative;
}
.sidebar-img img {
    border-radius: 15px;
    width: 100%;
    padding: 6px;
    padding-bottom: 33px;
}
.sidebar-ul {
    padding: 0;
    list-style: none;
    margin: 0;
}
.sidebar-ul li a {
    font-size: 14px;
    color: var(--second-color);
    font-family: 'poppins-regular';
    text-decoration: unset;
}
.sidebar-ul li a:hover {
    font-family: 'poppins-semibold';
}
.sidebar-ul li {
    padding: 18px 9px;
    border-bottom: 1px solid #0000000f;
}
.profile-edit-btn {
    margin-top: 15px;
}
.side-abr-icon {
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: end;
    justify-content: center;
}
.side-abr-icon p {
    font-size: 14px;
    color: var(--third-color);
    font-family: 'poppins-regular';
    text-decoration: underline;
    margin: 0;
    font-weight: 100;
    cursor: pointer;
}
/* ============ */

/* Begin of Responsive Section */
@media (max-width: 1920px) {

}
@media (max-width: 1600px) {

}
@media (max-width: 1440px) {

}
@media (max-width: 1366px) {

}
@media (max-width: 1280px) {

}
@media (max-width: 1100px) {
   
}
@media (max-width: 1024px) {
    .show-my-profile p {
        font-size: 11px;
      
    }
    .sidebar-ul li {
        padding: 18px 8px;
    }
    .show-profile {
        padding: 20px 10px;
    }

 

}
@media (max-width: 991px) {
    .profile-edit-btn a {
        padding: 10px 18px;
    
    }
    .all-table-order {
        overflow-x: scroll;
        width: 580px;
    }
}
@media (max-width: 820px) {
    .all-table-order {
        overflow-x: scroll;
        width: 553px;
    }
    .all-table-order td {
        font-size: 10px;
   
    }
    .all-table-order th {
        font-size: 11px;
    }

}
@media (max-width: 768px) {

}
@media (max-width: 575px) {
    .profile-edit-btn a {
        padding: 10px 48px;
    }
    .all-edit-screen {
        padding: 30px 10px;
    }

    .all-table-order th {
        font-size: 11px;
        min-width: 100px;
    }
    .all-table-order {
        overflow-x: scroll;
        width: 100%;
    }
    .all-table-order td button {
        font-size: 10px;
    }
    .all-edit-screen {
        margin-top: 27px;
    }
    .show-profile {
        margin-top: 27px;
    }
    .all-table-order {
        overflow-x: scroll;
        width: 100%;
        margin-top: 27px;
    }
}
/* ===================== */