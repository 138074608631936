.blog-detail-img img {
    width: 100%;
    border-radius: 60px;
    filter: brightness(0.5);
}
.blog-detail-text p {
    font-size: 10px;
    color: var(--second-color);
    font-family: 'poppins-regular';
}
.blog-detail-text {
    padding: 40px 0px;
}
.blog-detail-img h4 {
    font-size: 30px;
    color: var(--second-color);
    font-family: 'poppins-semibold';
}
.blog-detail-img {
    text-align: center;
    padding-bottom: 14px;
}
/* Begin of Responsive Section */
@media (max-width: 1920px) {

}
@media (max-width: 1600px) {

}
@media (max-width: 1440px) {

}
@media (max-width: 1366px) {

}
@media (max-width: 1280px) {

}
@media (max-width: 1100px) {
    .blogs-head p {
        width: 85%;
        
    }
   
}
@media (max-width: 1024px) {
 

}
@media (max-width: 991px) {
    .content-wrapper {
        padding-top: 20px;
    }

}
@media (max-width: 820px) {

}
@media (max-width: 768px) {

}
@media (max-width: 575px) {
    .blogs-head h5 {
        font-size: 20px;
    }
    .blogs-head {
        padding-top: 0;
    }
    .blogs-head p {
        width: 100%;
    }
    .blogs-head p {
        font-size: 10px;
    }
    .blog-detail-img img {
        border-radius: 10px;
    }
    .blog-detail-img h4 {
        font-size: 18px;
    }
    .blog-detail-text {
        padding: 0px 0px;
    }
}
/* ===================== */