.right-job-head h4 {
    font-size: 30px;
    color: var(--first-color);
    font-family: 'poppins-semibold';
    margin: 0;
}
.right-job-head p {
    font-size: 12px;
    color: var(--second-color);
    font-family: 'poppins-regular';
    margin: 0;
    width: 50%;
    margin: 0 auto;
}
.right-job-head {
    text-align: center;
}
section.right-job-sec {
    padding-bottom: 60px;
}
.career-text h3 {
    font-size: 23px;
    font-family: 'poppins-semibold';
    color: var(--second-color);
    margin: 0;
    padding-bottom: 15px;
}
.career-img img {
    width: 100%;
    border-radius: 20px;
}
.career-text p {
    font-size: 12px;
    color: var(--second-color);
    font-family: 'poppins-regular';
    margin: 0;
}
.career-text {
    text-align: center;
    padding: 30px 0px;
}
.open-first-dv h3 {
    font-size: 20px;
    color: var(--second-color);
    font-family: 'poppins-semibold';
    margin: 0;
}
.open-first-dv p {
    font-size: 15px;
    color: var(--second-color);
    font-family: 'poppins-regular';
    margin: 0;
}
.open-dv {
    display: flex;
    justify-content: space-between;
}
.full-time-btn a {
    box-shadow: 0px 0px 10px #0000004f;
    font-size: 15px;
    background-color: var(--first-color);
    padding: 7px 16px;
    border-radius: 7px;
    color: var(--third-color);
    text-decoration: unset;
}
.open-all-job-para p {
    font-size: 12px;
    color: var(--second-color);
    font-family: 'poppins-regular';
    margin: 0;
    padding: 15px 0px;
}
.open-all-job-para a {
    font-size: 14px;
    color: var;
    color: var(--sixth-color);
    font-family: 'poppins-semibold';
}
.open-job-all {
    box-shadow: 0px 3px 14px #0000004a;
    padding: 20px;
    border-radius: 16px;
    margin: 14px 0px;
}
.open-job-sec {
    padding: 36px 0px;
}
.caree-view-more-btn a {
    font-size: 16px;
    font-family: 'poppins-regular';
    text-decoration: unset;
    background-color: var(--first-color);
    padding: 10px 30px;
    color: var(--third-color);
    border-radius: 6px;
    text-transform: capitalize;
}
.caree-view-more-btn {
    text-align: center;
    padding: 50px 0px;
}
.work-with-sec {
    background-color: var(--back-color);
    padding: 70px 0px;
}
.work-with-head h3 {
    font-size: 32px;
    color: var(--second-color);
    font-family: 'poppins-semibold';
}
.work-with-head {
    text-align: center;
    padding-bottom: 40px;
}

/* Begin of Career Detail Css */
.job-description-img img {
    border: 3px solid #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 30px #dcdcdc;
}
.job-head-text h3 {
    font-size: 30px;
    color: var(--second-color);
    font-family: 'poppins-semibold';
    margin: 0;
    padding-bottom: 20px;
}
.job-head-text p {
    font-size: 13px;
    color: var(--second-color);
    font-family: 'poppins-regular';
}
.resposibility-text {
    padding-top: 50px;
}
.job-description .row {
    padding-top: 80px;
}
.simple-job-application-form-sec {
    background-color: var(--back-color);
    padding: 110px 0px;
}
.application-form-input input {
    width: 100%;
    padding: 10px 26px;
    border: none;
    box-shadow: 0px 3px 0px #dcdcdc;
    margin: 7px 0px;
    border-radius: 5px;
    outline: none;
}
.application-form-input textarea {
    width: 100%;
    padding: 10px 26px;
    border: none;
    box-shadow: 0px 3px 0px #dcdcdc;
    border-radius: 5px;
    margin: 7px 0px;
    height: 130px;
    resize: none;
}
.application-form-input input::placeholder {
    font-size: 13px;
    color: #00000078;
    font-family: 'poppins-meduim';
}
.application-form-input textarea::placeholder {
    font-size: 13px;
    color: #00000078;
    font-family: 'poppins-meduim';
}
.for-hide-input {
    font-size: 8px;
    opacity: 0;
    position: absolute;
    left: 0;
}
.application-button-input button {
    background-color: var(--third-color);
    border: none;
    padding: 10px;
    font-size: 16px;
    color: var(--first-color);
    width: 55%;
    font-family: 'poppins-regular';
    position: relative;
    border-radius: 6px;
    margin: 7px 0px;
    box-shadow: 0px 3px 0px #dcdcdc;
}
.application-button-input img {
    margin-left: 20px;
}
.apply-now-btn button {
    font-size: 15px;
    font-family: 'poppins-regular';
    width: 55%;
    background-color: var(--first-color);
    padding: 12px;
    border: none;
    color: var(--third-color);
    border-radius: 6px;
}
.apply-now-btn {
    padding-top: 40px;
}
.applicaiton-form {
    width: 70%;
    margin: 0 auto;
}
/* ================================ */

/* Begin of Responsive Section */
@media (max-width: 1920px) {

}
@media (max-width: 1600px) {

}
@media (max-width: 1440px) {

}
@media (max-width: 1366px) {

}
@media (max-width: 1280px) {

}
@media (max-width: 1100px) {
    .right-job-head p {
        width: 60%;  
    }
    .career-text p {
        font-size: 10px;
    }
    .open-first-dv h3 {
        font-size: 14px;
    }
    .open-all-job-para p {
        font-size: 9px;
    }
    .open-first-dv p {
        font-size: 11px;
    }
    .full-time-btn a {
        font-size: 12px;
    }
    section.right-job-sec {
        padding-bottom: 50px;
    }
}
@media (max-width: 1024px) {
 

}
@media (max-width: 991px) {
    .full-time-btn a {
        font-size: 9px;
        padding: 7px 9px;
    }
    .open-job-all {
        padding: 20px 8px;
    }
    .right-job-head h4 {
        font-size: 26px;
    }
    .right-job-head p {
        width: 90%;
    }
    .career-text h3 {
        font-size: 20px;
    }
    .job-head-text p {
        font-size: 10px;
    }
    .application-button-input button {
        width: 72%;
    }
    .apply-now-btn button {
        width: 55%;
    }
    .work-with-head h3 {
        font-size: 29px;
    }
    .simple-job-application-form-sec {
        background-color: var(--back-color);
        padding: 50px 0px;
    }
}
@media (max-width: 820px) {

}
@media (max-width: 768px) {

}
@media (max-width: 575px) {
    .right-job-head h4 {
        font-size: 15px;
        padding-bottom: 10px;
    }
    .work-with-sec {
        padding-top: 12px;
    }
    .work-with-head h3 {
        font-size: 29px;
    }
    .job-description-img img {
        width: 100%;
    }
    .job-description .row {
        padding-top: 20px;
    }
    .job-all-descrciption-dv {
        text-align: center;
    }
    .applicaiton-form {
        width: 100%;
    }
    .application-button-input button {
        width: 100%;
    }
    .apply-now-btn button {
        width: 100%;
    }
    .open-job-sec {
        padding-top: 0;
    }
    .full-time-btn a {
        font-size: 11px;
        padding: 7px 15px;
    }
}
/* ===================== */