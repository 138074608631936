.faq-all button.accordion-button.collapsed {
    box-shadow: unset;
}
.faq-all {
    box-shadow: 0px 0px 20px #00000038;
    padding: 50px 30px;
    border-radius: 30px;
    width: 70%;
    margin: 0 auto;
}
.faq-all h5 {
    font-size: 16px;
    color: var(--second-color);
    font-family: 'poppins-semibold';
}
.faq-sec {
    padding: 70px 0px;
    padding-top: 30px;
}
.faq-all p {
    font-size: 14px;
    color: #6D6D6D;
    font-family: 'poppins-regular';
}

.faq-sec  .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
    opacity: 0;
}

.faq-sec  button.accordion-button.collapsed::before {
    content: '\f067 ';
    font-family: 'FontAwesome';
    position: absolute;
    right: 10px;
}

.faq-sec button.accordion-button::before {
    content: '\f068';
    font-family: 'FontAwesome';
    position: absolute;
    right: 9px;
}

.faq-sec .accordion-button:not(.collapsed) {
    color: var(--second-color);
    background-color: unset;
    box-shadow: unset ;
}

/* Begin of Responsive Section */
@media (max-width: 1920px) {

}
@media (max-width: 1600px) {

}
@media (max-width: 1440px) {

}
@media (max-width: 1366px) {

}
@media (max-width: 1280px) {

}
@media (max-width: 1100px) {
   
}
@media (max-width: 1024px) {
 

}
@media (max-width: 991px) {
    .faq-all {
        padding: 50px 30px;
        width: 100%;  
    }

}
@media (max-width: 820px) {

}
@media (max-width: 768px) {

}
@media (max-width: 575px) {
    .faq-all {
        padding: 50px 4px;
        width: 100%;
    }

}
/* ===================== */