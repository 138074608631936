.professional-input-form input {
    width: 100%;
    padding: 12px 22px;
    border: none;
    box-shadow: 0px 2px 10px #0000005c;
    margin: 10px;
    border-radius: 7px;
    outline: none;
}
.professional-plan-input-form-btn button {
    width: 15%;
    padding: 10px;
    background-color: var(--first-color);
    border: unset;
    color: var(--third-color);
    font-family: 'poppins-meduim';
    border-radius: 8px;
}
.professional-plan-input-form-btn {
    text-align: center;
    padding-top: 20px;
}
.professianol-plan-dv p {
    font-size: 14px;
    color: #404040;
    font-family: 'poppins-meduim';
    margin: 0;
    width: 76%;
    margin: 0 auto;
    padding-bottom: 20px;
}
.professianol-plan-dv {
    text-align: center;
}
.professional-plan-form {
    background-color: #EFEFEF;
    padding: 40px;
    border-radius: 20px;
    width: 75%;
    margin: 0 auto;
}
.professianal-sec {
    padding: 70px 0px;
    padding-top: 16px;
}
.professional-input-form input::placeholder {
    font-size: 12px;
    color: #0000008f;
    font-family: 'poppins-meduim';
}
.professional-input-form {
    position: relative;
}
.icon-for-show-password {
    position: absolute;
    top: 20px;
    right: 10px;
    color: #B5B5B5;
}
/* Begin of Payment Detail Css */
.payment-all-input input {
    width: 100%;
    padding: 12px 22px;
    border: none;
    box-shadow: 0px 2px 10px #0000005c;
    margin: 10px;
    border-radius: 7px;
    outline: none;
}
.payment-all-input-things {
    background-color: #F2F2F2;
}
.payment-all-input-things {
    background-color: #F2F2F2;
    padding: 40px 30px;
    border-radius: 10px;
}
.payment-all-input input::placeholder {
    font-size: 12px;
    color: #0000008f;
    font-family: 'poppins-meduim';
}
/* ===================== */

/* Begin of Payment Css */
.payment-professinal-plan h4 {
    font-size: 10px;
    color: var(--third-color);
    font-family: 'poppins-regular';
    text-transform: uppercase;
    margin: 0;
    text-align: center;
    background-color: var(--sixth-color);
    padding: 20px 10px;
    border-radius: 10px;
}
.pay-para p {
    font-size: 12px;
    color: #656565;
    font-family: 'poppins-meduim';
    margin: 0;
}
.payment-all-the-text {
    display: flex;
    align-items: center;
}
.pay-para p span {
    font-family: 'poppins-regular';
    color: #B1B1B1;
}
.payment-professinal-plan {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.pay-para {
    padding-left: 10px;
}
.payment-all-first-heading h4 {
    font-size: 16px;
    color: var(--second-color);
    font-family: "poppins-bold";
    margin: 0;
    padding-bottom: 14px;
}
.payment-total-head h3 {
    font-size: 16px;
    color: var(--second-color);
    font-family: 'poppins-semibold';
    margin: 0;
}
.payment-total-dv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #C5C5C5;
    margin-top: 15px;
    padding-top: 15px;
}
.payemnt-all-stuff {
    background-color: #F2F2F2;
    padding: 34px 30px;
    border-radius: 10px;
}
.payment-tag h5 {
    font-size: 16px;
    color: var(--second-color);
    margin: 0;
    font-family: 'poppins-meduim';
}
.payment-total-tag h5 {
    font-size: 16px;
    color: var(--second-color);
    margin: 0;
    font-family: 'poppins-meduim';
}
.payment-head-all h3 {
    font-size: 16px;
    color: var(--second-color);
    font-family: 'poppins-meduim';
    margin: 0;
    padding-bottom: 20px;
    text-transform: capitalize;
}
.payment-total-btn button {
    width: 40%;
    background-color: var(--first-color);
    padding: 10px;
    font-size: 15px;
    color: var(--third-color);
    font-family: 'poppins-regular';
    border: none;
    border-radius: 7px;
}
.payment-total-btn {
    text-align: end;
    padding-top: 20px;
}
.payment-sec {
    padding: 50px;
    padding-bottom: 70px;
}
.payment-top-para p {
    font-size: 12px;
    color: var(--second-color);
    font-family: 'poppins-regular';
    margin: 0;
    line-height: 1px;
}
.payment-top-para {
    text-align: center;
}
/* ================ */

/* Begin of Payment thank you Css */
.thank-you-modal h3 {
    font-size: 28px;
    color: var(--second-color);
    font-family: 'poppins-bold';
    margin: 0;
}
.thank-you-modal p {
    font-size: 19px;
    color: #404040;
    font-family: 'poppins-regular';
    margin: 0;
}
.thank-you-modal {
    padding: 30px 0px;
    text-align: center;
}
.payment-thank-you-icon i {
    font-size: 70px;
    color: var(--first-color);
}
.payment-thankyou .modal-body {
    background-color: #F2F2F2;
    border-radius: 10px;
}
/* ============== */

/* Begin of Sign-in Modal Css */
.seller-signin-btn h3 {
    font-size: 30px;
    color: var(--second-color);
    font-family: 'poppins-bold';
}
.seller-signin-input input {
    width: 100%;
    padding: 8px;
    border: none;
    margin: 10px 0px;
    box-shadow: 0px 3px 10px #00000045;
    border-radius: 7px;
    outline: none;
}
.seller-signin-btn {
    text-align: center;
}
.check-and-forget {
    display: flex;
    justify-content: space-between;
}
.check-and-forget label {
    font-size: 13px;
    color: var(--tenth-color);
    font-family: 'poppins-regular';
}
.modal-forget-link a {
    font-size: 13px;
    color: var(--tenth-color);
    font-family: 'poppins-regular';
    text-decoration: underline;
}
.check-and-forget input {
    margin-right: 6px;
}
.check-and-forget-btn button {
    width: 32%;
    padding: 12px 10px;
    background-color: var(--first-color);
    border: none;
    font-size: 12px;
    color: var(--third-color);
    font-family: 'poppins-regular';
    letter-spacing: 1px;
    border-radius: 7px;
   
}
.check-and-forget-btn {
    text-align: center;
    padding-top: 20px;
}
.seller-center-modal-for-signin .modal-body {
    padding: 50px;
    background-color: #F2F2F2;
    border-radius: 20px;
}
.seller-signin-input {
    position: relative;
}
.seller-center-password-icon i {
    position: absolute;
    bottom: unset;
    right: 0;
    color: #B5B5B5;
}
/* ==================== */


.check-and-forget input#check-remeber {
    accent-color: #7dab3c;
}
/* Begin of Responsive Section */
@media (max-width: 1920px) {

}
@media (max-width: 1600px) {

}
@media (max-width: 1440px) {

}
@media (max-width: 1366px) {

}
@media (max-width: 1280px) {

}
@media (max-width: 1100px) {
    .professianol-plan-dv p {
        width: 96%;
    }
    .professional-plan-input-form-btn button {
        width: 25%;
    }
    .pay-para p {
        font-size: 9px;
    }
   
}
@media (max-width: 1024px) {
 

}
@media (max-width: 991px) {
    .professional-plan-form {
        width: 100%;
    }
    .payemnt-all-stuff {
        padding: 34px 7px;
    }
    .payment-professinal-plan h4 {
        padding: 20px 4px;
        
    }
}
@media (max-width: 820px) {

}
@media (max-width: 768px) {

}
@media (max-width: 575px) {
    .professional-plan-form {
        padding: 40px 20px;  
        padding-left: 5px;
    }
    .professianol-plan-dv p {
        width: 100%;
    }
    .professional-plan-input-form-btn button {
        width: 50%;
        margin: 0 auto;
    }
    .professianol-plan-dv p {
        font-size: 11px;
        padding-left: 11px;
    }
    .payment-sec {
        padding: 50px 0px;
        padding-top: 0;
    }
    .payment-all-input-things {
        padding-left: 10px;
    }
    .payment-top-para p {
        font-size: 11px;
        line-height: 14px;
        width: 75%;
        margin: 0 auto;
    }
    .payment-head-all {
        padding-top: 20px;
    }
    .thank-you-modal h3 {
        font-size: 11px;
    }
    .thank-you-modal p {
        font-size: 10px;
    }
    .payment-thank-you-icon img {
        width: 18%;
        margin-top: 10px;
    }
    .thank-you-modal {
        padding-bottom: 14px;
    }
    .payment-total-btn {
        text-align: center;
        padding-top: 20px;
    }
    .payment-head-all {
        padding-top: 20px;
        text-align: center;
    }
}
/* ===================== */