.get-quote-upload button span {
    font-size: 13px;
    color: #00000085;
    font-family: 'poppins-meduim';
    padding-right: 40px;
}
.for-hide-quote-input {
    position: absolute;
    left: 0;
    opacity: 0;
}
.get-quote-upload button {
    width: 100%;
}
.get-quote-upload img {
    position: absolute;
    right: 20px;
    top: 7px;
}
.get-quote-upload button {
    width: 100%;
    padding: 9px 20px;
}
.get-quaote-button button {
    font-size: 14px;
    color: var(--third-color);
    font-family: 'poppins-regular';
    width: 20%;
    padding: 10px;
    border: none;
    background-color: var(--first-color);
    border-radius: 7px;
}
.get-quaote-button {
    text-align: center;
    padding: 30px;
}
.get-quote-input input {
    width: 100%;
    padding: 10px 20px;
    margin: 10px 0px;
    border: none;
    box-shadow: 0px 5px 10px #00000054;
    border-radius: 7px;
}
.get-quote-mcq-dv-input {
    margin: 10px 0px;
    background-color: var(--third-color);
}
.get-quote-head h5 {
    font-size: 20px;
    color: var(--second-color);
    font-family: "poppins-bold";
}
.get-quote-head {
    padding-top: 40px;
}
.get-quaote-form {
    border-radius: 20px;
    width: 70%;
    margin: 0 auto;
    background-color: #F6F6F6;
    padding: 40px;
}
.get-quote-sec {
    padding-bottom: 90px;
}
.get-quote-input input::placeholder {
    font-size: 12px;
    color: #00000080;
    font-family: 'poppins-meduim';
}

/* Begin of Responsive Section */
@media (max-width: 1920px) {

}
@media (max-width: 1600px) {

}
@media (max-width: 1440px) {

}
@media (max-width: 1366px) {

}
@media (max-width: 1280px) {

}
@media (max-width: 1100px) {
    .get-quote-upload button span {
        font-size: 10px;
    }

   
}
@media (max-width: 1024px) {
    .mcq-dv-input label {
        font-size: 10px;
    }
 

}
@media (max-width: 991px) {
    .get-quaote-form {
        width: 100%;   
    }

}
@media (max-width: 820px) {

}
@media (max-width: 768px) {

}
@media (max-width: 575px) {
    .for-hide-quote-input {
        font-size: 0;
    }
    .get-quaote-form {
        padding: 0px 5px;
    }
    .get-quote-head h5 {
        text-align: center;
    }
    .get-quaote-button button {
        width: 100%;

    }
}
/* ===================== */