.for-views {
    display: flex;
}
.recipe-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.recipe-para h4 {
    font-size: 11px;
    color: var(--second-color);
    font-family: 'poppins-meduim';
    margin: 0;
    padding: 10px 0px;
}
.recipe-flex p {
    font-size: 14px;
    color: var(--first-color);
    font-family: 'poppins-meduim';
    margin: 0;
}
.for-views h5 {
    font-size: 12px;
    color: #8D8D8D;
    font-family: 'poppins-meduim';
    margin: 0;
    padding-right: 10px;
}
.recipe-img img {
    width: 100%;
    border-radius: 20px;
}
.recipe-sec {
    padding: 70px 0px;
    padding-top: 20px;
}
.recipe-detail-para-all p {
    font-size: 20px;
    color: var(--second-color);
    font-family: "poppins-bold";
    margin: 0;
    padding: 15px 0px;
}
.df-heading {
    display: flex;
    align-items: center;
    padding-bottom: 60px;
}
.df-heading h3 span {
    font-size: 20px;
    background-color: var(--first-color);
    padding: 8px 10px;
    border-radius: 60px;
    color: var(--third-color);
    font-family: 'poppins-bold';
    text-transform: uppercase;
}
.df-heading h3 {
    font-size: 16px;
    color: var(--second-color);
    font-family: 'poppins-meduim';
    
}
.recipe-detail-img img {
    border-radius: 40px;
    border: 4px solid #ffffff;
    box-shadow: 0px 0px 10px #00000069;
}
.recipe-all-text {
    background-color: #F2F2F2;
    padding: 30px;
}
.recipe-all-text h5 {
    font-size: 25px;
    color: var(--second-color);
    font-family: 'poppins-bold';
    margin: 0;
    padding-bottom: 20px;
}
.recipe-all-text p {
    font-size: 12px;
    color: var(--tenth-color);
    font-family: 'poppins-regular';
}
.recipe-all-text {
    background-color: #F2F2F2;
    padding: 30px;
    border-radius: 20px;
}
.recipe-sec a {
    text-decoration: unset;
}
.recipe-all {
    margin: 15px 0px;
}
.for-padding-space {
    padding-left: 140px;
}

/* Begin of Responsive Section */
@media (max-width: 1920px) {

}
@media (max-width: 1600px) {

}
@media (max-width: 1440px) {

}
@media (max-width: 1366px) {

}
@media (max-width: 1280px) {

}
@media (max-width: 1100px) {
    .recipe-para h4 {
        font-size: 9px;
    }
    .recipe-flex p {
        font-size: 11px;
    }
    .for-views h5 {
        font-size: 9px;
    }
}
@media (max-width: 1024px) {
 

}
@media (max-width: 991px) {
    .for-views h5 {
        font-size: 8px;
        padding-right: 0px;
    }
    .for-padding-space {
        padding-left: 189px;
    }

}
@media (max-width: 820px) {

}
@media (max-width: 768px) {

}
@media (max-width: 575px) {
    .recipe-para h4 {
        font-size: 12px;
    }
    .recipe-flex p {
        font-size: 14px;
    }
    .for-views h5 {
        font-size: 10px;
        padding-right: 10px;
    }
    .recipe-sec {
        padding-bottom: 0px;
    }
    .recipe-detail-para-all p {
        font-size: 13px;
        padding: 15px 0px;
    }
    .pagination-all .page-link {
        font-size: 18px;
        padding: 0px 10px;
      }
      .for-padding-space {
        padding-left: 0px;
        padding-right: 19px;
    }
    .df-heading h3 {
        font-size: 11px;
    }
    .df-heading {
        display: flex;
        justify-content: space-between;
    }
    .df-heading {
        padding-bottom: 30px;
    }
    .recipe-all-text {
        background-color: #F2F2F2;
        padding: 30px 14px;
        border-radius: 20px;
        margin-bottom: 40px;
    }
    .recipe-detail-img img {
        border-radius: 10px;

    }
}
/* ===================== */