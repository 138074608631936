.seller-header-sec {
  background: #485f27;
  padding-top: 39px;
  padding-bottom: 39px;
}
.seller-header-sec .container-fluid {
  padding: 0px 57px;
}
.seller-header-sec .seller-profile-logo-wrapper {
  display: flex;
  justify-content: end;
}
.seller-header-sec .seller-profile-logo-wrapper img {
  height: 47px;
  width: 47px;
  border: 1px solid #7dab3c;
  border-radius: 50%;
  object-fit: cover;
}
.seller-header-sec .seller-profile-name-wrapper {
  margin-top: 57px;
  display: flex;
  align-items: center;
}
.seller-header-sec .seller-profile-name-wrapper .seller-img-wrapper {
  margin-right: 30px;
}
.seller-header-sec .seller-profile-name-wrapper .seller-content-wrapper h4 {
  font-family: "poppins-meduim";
  font-size: 25px;
  color: #fff;
}
.seller-header-sec .seller-profile-name-wrapper .seller-content-wrapper h6 {
  font-family: "poppins-meduim";
  font-size: 18px;
  color: #fff;
}
.seller-header-sec .seller-profile-name-wrapper .seller-img-wrapper img {
  height: 79px;
  width: 79px;
  border-radius: 50%;
  border: 1px solid #7dab3c;
  object-fit: cover;
}
.seller-header-sec .seller-search-wrapper input.form-control {
  height: 42px;
  background: #ffffff73;
  border: 0px;
  background-image: url("../img/seller-profile/search.png");
  background-repeat: no-repeat;
  background-position: 2% center;
  padding: 0px 46px;
  border-radius: 11px;
}
/* seller header starts here */
.seller-sidebar-sec .sidebar-links-wrapper ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.seller-sidebar-sec .sidebar-links-wrapper ul li.sidebar-links a {
  display: flex;
  align-items: inherit;
  text-decoration: none;
}
.seller-sidebar-sec .sidebar-links-wrapper ul li.sidebar-links a span.icon {
  background: #f0f0f0;
  flex: 0 0 17%;
  text-align: center;
  padding: 20px 2px;
  font-size: 27px;
  color: #7dab3c;
  box-shadow: 3px 3px 3px 0px #ececec;
}
.seller-sidebar-sec .sidebar-links-wrapper ul li.sidebar-links a span.text {
  padding: 25px 20px;
  font-family: "poppins-regular";
  color: #030303;
  font-size: 16px;
  border-bottom: 1px solid #f4f4f4;
  flex: 84% 1;
  background-color: #ffffff;
  text-transform: capitalize;
  padding-top: 33px;
}
.seller-sidebar-sec .sidebar-links-wrapper ul li.sidebar-links a span.text:hover {
  font-family: 'poppins-semibold';
}
.seller-sidebar-sec
  .sidebar-links-wrapper
  ul
  li.sidebar-links:last-child
  a
  span.icon {
  box-shadow: 3px 0px 3px 0px #ececec;
  border-radius: 0px 0px 19px 0px;
}
.seller-sidebar-sec
  .sidebar-links-wrapper
  ul
  li.sidebar-links:first-child
  a
  span.icon {
  border-radius: 0px 19px 0px 0px;
}
/* selkler header ends here *

/* Begin of Seller Edit Profile Css */
.seller-edit-form-input input {
  width: 100%;
  position: relative;
  padding: 10px 30px;
  border: unset;
  box-shadow: 0px 4px 5px #0000002e;
  border-radius: 6px;
  margin: 10px 0px;
  outline: none;
}
.seller-edit-form-input {
  position: relative;
}
.seller-dashboard-icon {
  position: absolute;
  top: 20px;
  left: 10px;
}
.seller-dashboard-icon i {
  font-size: 12px;
  color: var(--first-color);
}
.seller-edit-form-input input::placeholder {
  font-size: 12px;
  color: #6C757D;
  font-family: 'poppins-regular';
}

.edit-details-form {
  display: flex;
}
.seller-save-btn button {
  margin-right: 0;
  font-size: 13px;
  border: unset;
  background-color: var(--first-color);
  color: var(--third-color);
  font-family: 'poppins-regular';
  width: 97%;
  padding: 8px;
  border-radius: 7px;
  margin-top: 10px;
  margin: 10 auto;
  margin-right: 0;
  font-weight: 100;
}
.seller-save-btn {
  text-align: center;
}
.seller-edit-form-input textarea {
  width: 100%;
  position: relative;
  padding: 25px 30px;
  border: unset;
  box-shadow: 0px 4px 10px #00000047;
  border-radius: 6px;
  margin: 10px 0px;
  height: 130px;
  resize: none;
}

.css-wsp0cs-MultiValueGeneric {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  color: #ffffff !important;
  font-size: 85%;
  padding: 3px;
  padding-left: 6px;
  box-sizing: border-box;
}
.css-1p3m7a8-multiValue {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  background-color: hsl(84.86deg 48.05% 45.29%) !important;
  border-radius: 2px;
  margin: 2px;
  box-sizing: border-box;
  color: #ffffff !important;
}
.css-t3ipsp-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0!important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  background-color: hsl(0, 0%, 100%);
  border-color: #2684FF;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: unset;
  box-sizing: border-box;
}
.seller-edit-form-input textarea::placeholder {
  font-size: 12px;
  color: #6C757D;
  font-family: 'poppins-regular';
}
.check-seller label {
  font-size: 12px;
  color: var(--second-color);
  font-family: 'poppins-regular';
}
.check-seller input[type=checkbox] {
  position: relative;
  border: 2px solid #ffffff;
  border-radius: 14px !IMPORTANT;
  background: none;
  cursor: pointer;
  line-height: 0;
  margin: 0 .6em 0 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: bottom;
  height: 20px;
  width: 14px;
  border: 1px solid #7dab3c;
}

.check-seller input[type=checkbox]:hover {
  opacity: 1;
}

.check-seller input[type=checkbox]:checked {
  background-color: transparent;
  opacity: 1;
  accent-color: #7dab3c;
}
.eyes-icon {
  position: absolute;
  top: 20px;
  right: 18px;
}
.eyes-icon i {
  color: #B5B5B5;
}
.seller-edit-with-border-dv {
  border: 1px solid #7dab3c;
  border-radius: 10px;
  padding: 30px;
}
/* =============== */

/* Begin of Product Inventory Css */
.seller-prdoduct-inventory-table th {
  font-size: 11px;
  color: var(--second-color);
  font-family: 'poppins-regular';
  font-weight: 200;
  background-color: var(--first-color);
  padding: 10px 10px;
  color: var(--third-color);
  text-align: center;
  white-space: nowrap;
}
.seller-prdoduct-inventory-table h5 {
  font-size: 10px;
  color: var(--second-color);
  font-family: 'poppins-meduim';
  margin: 0;
  text-align: center;
}
.product-inventory-para {
  font-size: 14px;
  width: 200px;
  font-family: 'poppins-regular';
  margin: 0;
}
.seller-prdoduct-inventory-table table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 16px;
}
.product-inventory-all button {
    font-size: 11px;
    color: var(--first-color);
    font-family: 'poppins-regular';
    width: 76%;
    margin: 4px 0px;
    border: 1px solid #7dab3c;
    background-color: transparent;
    border-radius: 6px;
    padding: 2px 0px;
}
.product-inventory-para p {
  font-size: 8px;
  color: var(--second-color);
  font-family: 'poppins-meduim';
  margin: 0;
}
.for-border-all-raduis {
  border-radius: 18px 0px 0px 6px;
}
.for-border-raduis-all-things {
  border-radius: 0px 20px 6px 0px;
}
.stock-img p {
  font-size: 10px;
  color: var(--first-color);
  font-family: 'poppins-regular';
  margin: 0;
  white-space: nowrap;
}
.delete-trash {
  text-align: center;
  color: #FF0007;
  cursor: pointer;
}
.price-para p {
  font-size: 10px;
  color: var(--second-color);
  font-family: 'poppins-meduim';
  margin: 0;
  text-align: center;
}
.seller-prdoduct-inventory-table {
  padding-top: 0px;
  overflow-x: scroll;
  width: 100%;
}
.inventry-img img {
  width: 60%;
  box-shadow: 0px 0px 10px #00000040;
  border-radius: 10px;
}
.product-inventory-all {
  text-align: center;
}
.edit-screen {
  text-align: center;
  color: var(--first-color);
  font-family: "poppins-bold";
  cursor: pointer;
}
.inactive {
  border: 1px solid #FF0000 !important;
  color: #FF0000 !important;
} 
.seller-prdoduct-inventory-table tr {
    box-shadow: 0px 3px 5px #00000021;
    border-radius: 10px;
}
.seller-prdoduct-inventory-table td {
  padding: 8px 0px;
  min-width: 100px;
  text-align: center;
}
.add-product-btn a {
  font-size: 13px;
  color: var(--third-color);
  background-color: var(--first-color);
  padding: 8px 16px;
  font-family: 'poppins-regular';
  text-decoration: unset;
  border-radius: 7px;
}
.add-product-btn {
  float: right;
  padding-top: 30px;
}

 .seller-prdoduct-inventory-table::-webkit-scrollbar {
    width: .3em;
    height: 5px;
  
}


.seller-prdoduct-inventory-table::-webkit-scrollbar-thumb {
    background-color: var(--first-color);
      border-radius: 10px;
    
    /* outline: 1px solid slategrey; */
}
/* ======================= */

/* Begin of Review Star Css */
.review-ul-star ul {
  padding: 0;
  list-style: none;
  display: flex;
  margin: 0;
}
.review-text h5 {
  font-size: 12px;
  color: #3B3B3B;
  font-family: 'poppins-bold';
  margin: 0;
}
.review-text p {
  font-size: 12px;
  color: #858585;
  font-family: 'poppins-meduim';
  margin: 0;
}
.review-ul-star ul li a i {
  font-size: 11px;
  color: #E69A00;
  padding-right: 5px;
}
.review-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.seller-store-review-img img {
  border: none;
}
.review-img img {
  border-radius: 80px;
  border: none;
}
.seller-review-img img {
  border: 3px solid #7dab3c;
}
.reviews-para p {
  font-size: 11px;
  color: #656565;
  font-family: 'poppins-regular';
  margin: 0;
}
.all-seller-date-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #7070702e;
  padding: 10px;
  margin-top: 20px;
}
.all-seller-date p {
  font-size: 10px;
  color: #656565;
  font-family: 'poppins-regular';
  margin: 0;
}
.all-seller-btn button {
  font-size: 13px;
  color: var(--third-color);
  background-color: var(--first-color);
  font-family: 'poppins-regular';
  border: none;
  padding: 8px;
  border-radius: 7px;
}
.seller-reviews-tabs .row {
  display: flex;
  align-items: center;
}
.review-text {
  padding-left: 10px;
}
.reviews-all-stuff {
  box-shadow: 0px 3px 8px #0000004f;
  padding: 20px;
  border-radius: 20px;
  margin-top: 30px;
}
.seller-reviews-tabs {
  height: 790px;
  overflow-x: auto;
}
.seller-reviews-tabs::-webkit-scrollbar {
  width: 1px;
}
.modal-submit-btn {
  text-align: end;
  padding: 16px;
}
.reviews-modal-para p {
  font-size: 20px;
  color: var(--second-color);
  font-family: 'poppins-bold';
  margin-top: 2px;
}
.reviews-modal-text textarea {
  height: 140px;
  resize: none;
}
.reviews-modal-text textarea#exampleFormControlTextarea1::placeholder {
  font-size: 10px;
  color: var(--second-color);
  font-family: 'poppins-meduim';
}
.modal-submit-btn button {
  width: 16%;
}
.review-modal .modal-content {
  padding: 7px 20px;
  background-color: #F9F9F9;
  border-radius: 10px;
}
/* ============= */

/* Begin of Payment Detail Css */
.saved-address label.container .select-address .selection {
  position: absolute;
  right: 8px;
  top: 16px;
  padding: 0;
  opacity: 0;
}

.saved-address label.container .select-address .selection i.fa.fa-check {
  background: #0eb702;
  color: #fff;
  font-size: 14px;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
}

 .saved-address label.container input:checked~span.checkmark .select-address .selection {
  opacity: 1;
}

.checkout .saved-address .title {
  padding-bottom: 25px;
}

 .saved-address .title h5 {
  font-size: 15px;
}

/* The container */
 .saved-address .container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
 .saved-address .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

 .saved-address span.checkmark {
  position: unset;
}

 .saved-address label.container {
  padding: 0;
}
.saved-address label.container .select-address {
  border: 1px solid #b5b5b56b;
  padding: 1px 30px;
  position: relative;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 10px #0000004d;
  padding: 4px 12px;
  border-radius: 7px;
}
.saved-address label.container input:checked~span.checkmark .select-address {
  border-color: var(--third-color);
  border: 2px solid #7DAB3C;
}

.saved-address .select-address .radio-btn span {
  width: 11px;
  height: 11px;
  background: #fff;
  position: absolute;
  left: 0;
  border-radius: 50%;
  border: 2px solid #1b390f;
  box-shadow: 0px 0px 0px 1px #1b390f;
  top: 2px;
  margin: 20px 20px;
}

.saved-address label.container input:checked~span.checkmark .select-address .radio-btn span {
  background: var(--first-color);
  border: 2px solid #ffffff;
}
.saved-address .select-address {
  position: relative;
}

.saved-address .select-address .address-detail {
  padding-left: 20px;
  margin-left: 30px;
  border-left: 1px solid #7070701a;
  margin-top: 7px;
}
 .saved-address .select-address .address-detail .top {
  display: flex;
}

 .saved-address .select-address .address-detail h5 {
  width: 100%;
  padding: 0px 0;
  margin: 4px 0px;
}
 .saved-address .select-address .address-detail h5 span.property {
  font-size: 14px;
  color: var(--fourth-color);
  font-weight: 500;
  font-family: poppins-bold;
}

 .saved-address .select-address .address-detail h5 span.value {
  margin-left: 20px;
  font-weight: 400;
  color: var(--second-color);
  font-size: 12px;
  font-family: poppins-regular;
}

.saved-address .select-address .address-detail h5.last span.value {
  margin-left: 33px;
}

.checkout .saved-address .select-address .button-group {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

 .saved-address .select-address .address-detail button.btn {
  background: #c9c9c945;
  color: #7d7d7d;
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  opacity: 1;
  border-radius: 0;
  padding: 6px 20px;
}

.saved-address .select-address .address-detail button.lnk {
  background: transparent;
  border: 0;
  font-size: 15px;
  color: #1473e6;
  text-decoration: underline;
  margin-left: 10px;
}
.checkout .add-new-address {
  padding-top: 20px;
  position: relative;
}

.checkout .form-group {
  margin-bottom: 15px;
}

.checkout .add-new-address label.container {
  padding: 0 25px 20px;
  font-size: 15px;
  color: #fff;
  font-weight: 500;
}

.checkout .add-new-address label.container span.checkmark {
  background: #fff;
  width: 15px;
  height: 15px;
  position: absolute;
  left: 0;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0px 0px 0px 1px #f35d63;
  top: 24.5px;
}

.checkout .add-new-address label.container input {
  position: absolute;
  left: 0px;
  opacity: 0;
}

.checkout .add-new-address label.container input:checked~span.checkmark {
  background: #fb7b1c;
}

.checkout .add-new-address .form-group label {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 10px;
}

.checkout .add-new-address .form-group .form-control {
  border-radius: 0;
  height: 50px;
  border-color: #fff;
  border-radius: 10px;
  background: transparent;
  color: #fff;
  font-weight: 400;
}

.checkout .add-new-address .checkbox {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.checkout .add-new-address .checkbox .form-control {
  width: auto;
}

.checkout .add-new-address .checkbox label {
  margin-bottom: 0;
  color: #636060;
  font-size: 15px;
  padding-left: 15px;
}

.address-edit-btn a {
  font-size: 12px;
  color: var(--first-color);
  font-family: "poppins-bold";
  text-transform: uppercase;
}

.my-all-address {
  width: 100%;
}

.for-some-space {
  padding-bottom: 30px;
}
.address-second {
  padding-bottom: 50px;
}
.visa-para h5 {
  font-size: 10px;
  color: var(--second-color);
  font-family: 'poppins-regular';
  margin: 0;
}
.visa-para p {
  font-size: 12px;
  color: #656565;
  font-family: 'poppins-semibold';
  margin: 0;
}
.visa-img img {
  width: 86%;
}
.for-flex-the-things {
  display: flex;
  align-items: center;
}
.selection-delete i {
  font-size: 13px;
  color: #FF0007;
}
.seller-delete {
  position: absolute;
  right: 10px;
  top: -9px;
}
.seller-default {
  position: absolute;
  top: -4px;
  right: 30px;
}
.seller-default button {
  font-size: 12px;
  background-color: var(--first-color);
  color: var(--third-color);
  border: none;
  padding: 6px 18px;
  border-radius: 8px;
  font-family: 'poppins-regular';
}
.payment-head h3 {
  font-size: 30px;
  color: var(--second-color);
  font-family: 'poppins-semibold';
  margin: 0;
  text-transform: capitalize;
}
.payment-input input {
  width: 100%;
  padding: 8px 16px;
  border: none;
  box-shadow: 0px 0px 10px #00000038;
  border-radius: 6px;
  outline: none;
}
.payment-head {
  padding: 30px 13px;
  padding-top: 10px;
}
.payment-input input::placeholder {
  font-size: 12px;
  color: #6c757d99;
  font-family: 'poppins-regular';
}
.payment-btn button {
  font-size: 14px;
  color: var(--third-color);
  font-family: 'poppins-regular';
  background-color: var(--first-color);
  width: 17%;
  border: none;
  padding: 10px;
  border-radius: 6px;
  margin-top: 20px;
}
/* ======================= */

/* Begin of Membership Plan Css */
.all-amount-paid {
  display: flex;
  justify-content: space-between;
}
.all-professional-plan {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 2px 3px #0000004f;
  border-radius: 7px;
  padding: 9px 15px;
  padding-top: 10px;
  margin-top: 10px;
  position: relative;
  align-items: center;
}
.all-professional-plan2 {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 2px 3px #0000004f;
  border-radius: 7px;
  padding: 2px 15px;
  /* padding-top: 0px; */
  margin-top: 10px;
  position: relative;
  align-items: center;
}
.membership-modal-head h2 {
  font-size: 30px;
  color: var(--second-color);
  font-family: 'poppins-semibold';
  margin: 0;
}
.membership-modal-head {
  text-align: center;
  padding-bottom: 50px;
}

.membership-modal .modal-body {
  padding: 40px 50px;
  background-color: #EFEFEF;
  border-radius: 20px;
}
.all-professional-plan::before {
  content: '';
  background-color: #B1B1B1;
  position: absolute;
  width: 1px;
  height: 30px;
  left: 194px !important;
  bottom: 5px;
}
.all-professional-plan2::before {
  content: '';
  background-color: #B1B1B1;
  position: absolute;
  width: 1px;
  height: 30px;
  left: 254px;
  bottom: 2px;
}
.all-amount-paid span h5 {
  font-size: 12px;
  color: var(--second-color);
  font-family: 'poppins-meduim';
  margin: 0;
  padding-right: 55px;
}
.seller-inner-profile-head {
  background-color: var(--sixth-color);
}
.seller-inner-profile-head h3 {
  font-size: 16px;
  color: var(--third-color);
  font-family: "poppins-bold";
  margin: 0;
  text-transform: uppercase;
  text-align: center;
}
.seller-head-profile h5 {
  font-size: 15px;
  color: var(--third-color);
  font-family: 'poppins-semibold';
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 10px;
}
.seller-head-profile p {
  font-size: 9px;
  color: var(--third-color);
  font-family: 'poppins-regular';
  margin: 0;
}
.seller-inner-profile-head {
    background-color: var(--sixth-color);
    padding: 20px 20px;
    border-radius: 10px;
    height: 160px;
}

.seller-head-profile {
  text-align: center;
  padding-top: 36px;
}
.seller-who h5 {
  font-size: 13px;
  color: var(--second-color);
  font-family: 'poppins-semibold';
  margin: 0;
  padding: 10px 0px;
}
.seller-who-ul ul {
  padding: 0;
  list-style: none;
}
.seller-who-ul ul li {
  font-size: 8px;
  color: var(--second-color);
  font-family: 'poppins-regular';
  margin: 0;
  display: flex;
  align-items: center;
  padding: 4px 0px;
}
.seller-who-icon {
  font-size: 17px;
  padding-right: 7px;
  color: var(--sixth-color);
}
.seller-professional-plan {
  padding: 14px 11px;
  box-shadow: 0px 0px 10px #00000026;
  border-radius: 22px;
  height: 582px;
}
.seller-center-all-membership-plan {
  margin: 10px;
}
.seller-who {
  padding: 5px 5px;
}
.professional-plan-heading h3 {
  font-size: 32px;
  color: var(--sixth-color);
  font-family: 'poppins-semibold';
  margin: 0;
  padding-bottom: 10px;
}
.professional-plan-heading p {
  font-size: 10px;
  color: var(--second-color);
  font-family: 'poppins-regular';
}
.professional-plan-heading {
  text-align: center;
  padding-bottom: 50px;
}
.professional-plan-heading p {
  font-size: 11px;
  color: var(--second-color);
  font-family: 'poppins-regular';
  width: 64%;
  margin: 0 auto;
}
.membership-plan-sec {
  padding: 60px 0px;
}
.seller-buy-now button {
  width: 50%;
  background-color: var(--sixth-color);
  padding: 10px 0px;
  font-size: 14px;
  color: var(--third-color);
  font-family: 'poppins-regular';
  border: none;
  border-radius: 6px;
}
.seller-buy-now {
  text-align: center;
  padding-bottom: 20px;
}
.seller-different-color {
  background-color: var(--first-color);
}
.seller-differt-color-btn button {
  background-color: var(--first-color);
}
.seller-center-sec {
  padding-top: 40px;
  padding-bottom: 100px;
}
.seller-img-postion-relative {
  position: relative;
}
.about-btn {
  position: absolute;
  right: 10px;
  top: -93px;
}
.about-btn a {
  font-size: 12px;
  color: var(--third-color);
  background-color: var(--first-color);
  font-family: 'poppins-regular';
  padding: 10px 30px;
  border-radius: 7px;
  text-decoration: unset;
}
.all-professional-plan span p {
  font-size: 12px;
  color: var(--second-color);
  font-family: 'poppins-regular';
  margin: 0;
  text-transform: uppercase;
  padding-right: 30px;
}
.all-professional-plan2 span p {
  padding-right: 50px;
}
.for-color-and-text-captalize p {
  font-family: 'poppins-semibold' !important;
}
.getting-ul ul {
  list-style: none;
  box-shadow: 0px 0px 10px #0000002e;
  padding: 10px;
  border-radius: 10px;
}
.getting-ul span  p {
  font-size: 10px;
  color: var(--second-color);
  font-family: 'poppins-regular';
  margin: 0;
}
.getting-ul ul li span i {
  color: var(--sixth-color);
}
.getting-ul ul li {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #70707026;
  padding: 4px;
}
.getting-ul ul li span p {
  padding-left: 12px;
}
.getting-dv-head h3 {
  font-size: 20px;
  color: var(--second-color);
  font-family: 'poppins-semibold';
}
.membership-plan {
  padding-top: 50px;
  border: 1px solid #7dab3c;
  border-radius: 10px;
}
.all-subscription-head h3 {
  font-size: 20px;
  color: var(--second-color);
  font-family: 'poppins-semibold';
  margin-bottom: 26px;
}
.all-professioanl-cancel {
  display: flex;
  justify-content: space-between;
}
.all-professioanl-cancel span p {
  font-size: 9px;
  color: #6C757D;
  font-family: 'poppins-regular';
  margin: 0;
}
.cancel-btn {
  display: flex;
  align-items: center;
}
.cancel-btn p {
  padding-right: 6px;
}
.all-professioanl-cancel {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
}
.upgrade-btn button {
  background-color: var(--first-color);
  font-size: 15px;
  color: var(--third-color);
  font-family: 'poppins-regular';
  width: 30%;
  padding: 8px;
  border: none;
  border-radius: 7px;
  margin-top: 20px;
}
.all-order-img {
  display: flex;
}
.for-para-width {
  width: 60%;
}
.order-text h4 {
  font-size: 14px;
  color: var(--second-color);
  font-family: 'poppins-meduim';
  margin: 0;
}
.order-text p {
  font-size: 12px;
  color: #00000082;
  font-family: 'poppins-regular';
}
.all-img-order-text {
  padding-left: 20px;
}
.order-move-up-down {
  padding-top: 26px;
}
.img-text-all h4 {
  font-size: 18px;
  color: var(--second-color);
  font-family: 'poppins-regular';
  margin: 0;
}
.img-text-all h5 {
  font-size: 14px;
  color: var(--second-color);
  font-family: 'poppins-regular';
  margin: 0;
}
.img-one {
  display: flex;
  align-items: center;
}
.img-one img {
  width: 100%;
  border-radius: 10px;
}
.all-img-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.img-text-all {
  padding-left: 10px;
}
.order-price h5 {
  font-size: 16px;
  color: var(--sixth-color);
  font-family: 'poppins-meduim';
  margin: 0;
}
.all-img-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
}
.order-total h5 {
  font-size: 15px;
  color: var(--second-color);
  font-family: 'poppins-meduim';
}
.order-total {
  display: flex;
  justify-content: space-between;
}
.item-total {
  border-top: 1px solid #C5C5C5;
  border-bottom: 1px solid #C5C5C5;
  padding: 20px 0px;
}
.order-sum {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}
.order-sum h3 {
  font-size: 20px;
  color: var(--sixth-color);
  font-family: 'poppins-meduim';
  margin: 0;
}
.order-head h2 {
  font-size: 32px;
  color: var(--first-color);
  font-family: "poppins-bold";
  margin: 0;
}
.order-head p {
  font-size: 14px;
  color: var(--second-color);
  font-family: 'poppins-regular';
  margin: 0;
}
.order-head {
  text-align: center;
  padding-bottom: 40px;
}
.order-total-modal .modal-body {
  padding: 50px;
  background-color: #F2F2F2;
  border-radius: 10px;
}
.all-table-order table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 16px;
}
.for-different-color {
  color: var(--first-color) !important;
  font-family: 'poppins-meduim' !important;
}
.all-professional-plan2 button#dropdownMenuButton1 {
  font-size: 11px;
  border: none;
  text-transform: uppercase;
  font-family: 'poppins-semibold';
}
.all-pro-plan2 {
  background-color: #ffffff;
  border-radius: 7px;
  box-shadow: 0px 4px 8px #00000042;
  margin-top: 7px;
}
.all-pro-plan2 .per-month-dv p {
  font-size: 14px;
  color: var(--second-color);
  font-family: 'poppins-regular';
  margin: 0;
  line-height: 39px;
}
.per-month-dv {
  text-align: center;
}
.all-pro-plan2  button#dropdownMenuButton1 {
  width: 100%;
  font-size: 14px;
  color: var(--second-color);
  font-family: 'poppins-semibold';
  text-transform: uppercase;
  padding: 0;
  line-height: 39px;
  border: none;
}
.all-pro-plan2  ul.dropdown-menu.show {
  width: 100%;
}
.col-show-right-border {
  border-right: 1px solid #B1B1B1;
}
.all-pro-plan2 .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
  color: var(--first-color);
  position: absolute;
  right: 0;
  top: 17px;
}
.all-pro-plan2 a.dropdown-item {
  font-size: 11px;
  color: var(--second-color);
  font-family: 'poppins-meduim';
  text-transform: uppercase;
}
/* ==================== */

/* Begin of Customer Order Css */
.customer-order-row {
  display: flex;
  align-items: center;
  padding: 0px 10px;
  justify-content: center;
}
.customer-order-row p {
  font-size: 12px;
  color: var(--second-color);
  font-family: 'poppins-regular';
  margin: 0;
  padding-left: 10px;
}
.for-extra-border-raduis {
  border-radius: 45px;
  border: 2px solid #7dab3c;
}
.for-slight-border-raduis {
  width: auto;
  border-radius: 10px;
}
.customer-para {
  font-size: 12px;
  color: var(--second-color);
  font-family: 'poppins-regular';
  margin: 0;
}
.seller-prdouct-customer-table th {
  text-align: center;
}
.seller-prdouct-customer-table td {
  text-align: center;
}
.customer-order-price {
  font-size: 12px;
  color: var(--second-color);
  font-family: 'poppins-regular';
  margin: 0;
}
.customer-order-paid {
  font-size: 12px !important;
  color: var(--first-color) !important;
  font-family: 'poppins-regular';
}
.seller-prdouct-customer-table button {
  background-color: var(--first-color);
  border: none;
  font-size: 12px;
  color: var(--third-color);
  font-family: 'poppins-regular';
  padding: 5px 13px;
  border-radius: 7px;
}
.seller-who-icon-two {
  color: var(--first-color);
}
.seller-edit-form-dropdown select {
  background-color: var(--third-color);
  border: none;
  box-shadow: 0px 4px 10px #00000047;
}
.seller-edit-form-dropdown select option {
  font-size: 16px;
  color: #6C757D;
  font-family: 'poppins-regular';
}
.seller-edit-form-dropdown select.form-select {
  font-size: 12px;
  color: #6C757D;
  font-family: 'poppins-regular';
  padding: 15px 28px;
  border: none;
}
.seller-edit-form-dropdown .form-select {
  display: block;
  width: 100%;
  padding: .375rem 2.25rem .375rem .75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url('../img/Fabricslider/down.png');
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
/* ============== */

/* Begin of Seller Forget Password Css */
.seller-forget-sec {
  padding: 90px 0px;
}
.seller-forget-input input {
  width: 100%;
  border: none;
  box-shadow: 0px 0px 10px #00000047;
  padding: 10px;
  border-radius: 6px;
  margin: 15px 0px;
  outline: none;
}
.seller-forget-form {
  text-align: center;
  width: 40%;
  padding: 52px 22px;
  margin: 0 auto;
  box-shadow: 0px 0px 10px #0000006b;
  border-radius: 40px;
}
.seller-forget-btn a {
  font-size: 20px;
  background-color: var;
  background-color: var(--first-color);
  color: var(--third-color);
  text-decoration: unset;
  width: 50%;
  display: block;
  text-align: center;
  padding: 6px;
  border-radius: 7px;
  text-transform: capitalize;
  margin: 0 auto;
}
.seller-forget-head h3 {
  font-size: 24px;
  color: var(--second-color);
  font-family: 'poppins-semibold';
  text-transform: capitalize;
}
.seller-again a {
  font-size: 14px;
  color: var(--second-color);
  font-family: 'poppins-meduim';
  text-transform: capitalize;
}
.seller-forget-btn {
  padding-bottom: 16px;
}
.seller-forget-input {
  position: relative;
}
/* ================ */
/* For Seller Active */
a.active {
  font-weight: 900;
}
.custom-radio [type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.custom-radio [type="radio"]:checked + label, [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 16px;
  display: inline-block;
  color: var(--first-color);
  font-family: 'poppins-semibold';
}
.custom-radio {
  border-bottom: 1px solid #B1B1B1;
}
.custom-radio [type="radio"]:checked + label:before, [type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 15px;
  height: 15px;
  border: 1px solid #000000;
  border-radius: 100%;
  background: #fff;
}
.custom-radio [type="radio"]:checked + label:after, [type="radio"]:not(:checked) + label:after {
  content: '';
  width: 9px;
  height: 9px;
  background: var(--first-color);
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
.custom-radio [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.custom-radio [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.custom-radio p {
  margin: 7px 0px;
}
.responsive-close-icon {
  position: absolute;
  right: 12px;
  top: 10px;
  z-index: 1;
}
.responsive-close-icon i {
  cursor: pointer;
}
/* ============== */

/* Begin of Other Css */
.customer-order-row img {
  width: 30%;
}
.for-member-modal-css p {
  font-size: 13px !important;
}
span.for-modal-amount h5 {
  padding-right: 76px !important;
}
/* =========== */


/* Begin of Responsive Section */
@media (max-width: 1920px) {
  .all-professional-plan::before {
    left: 288px;
    
}

}
@media (max-width: 1600px) {
  .all-amount-paid span h5 {
    font-size: 14px;
    color: var(--second-color);
    font-family: 'poppins-semibold';
    margin: 0;
    padding-right: 73px;
}
.all-professional-plan span p {

  padding-right: 56px;
}
.all-professional-plan::before {
  left: 228px !important;
}

}
@media (max-width: 1440px) {

}
@media (max-width: 1366px) {
  .all-professional-plan::before {
    left: 183px !important;
}
.all-professional-plan span p {
  padding-right: 36px;
}
.all-amount-paid span h5 {
  padding-right: 56px;
}

}
@media (max-width: 1280px) {

}
@media (max-width: 1100px) {
  .seller-inner-profile-head h3 {
    font-size: 15px;
}
.seller-head-profile {
    text-align: center;
    padding-top: 17px;
}
.seller-professional-plan {
    height: 572px;
}
.customer-order-row p {
  font-size: 8px;
}
.seller-prdouct-customer-table button {
  font-size: 7px;
}
.customer-para {
  font-size: 9px;
}
.edit-profile-card {
  padding: 43px 20px !important;
}
.customer-order-price {
  font-size: 9px;
}
.getting-ul ul li span p {
  padding-left: 6px;
}
.getting-ul span  p {
  font-size: 9px;

}
.all-professional-plan::before {
  left: 174px;
 
}
}
@media (max-width: 1024px) {
  .all-professioanl-cancel span p {
    font-size: 9px;
}
.all-professional-plan span p {
  font-size: 9px;
}
.all-professional-plan::before {
  left: 160px;
}
 

}
@media (max-width: 991px) {

}
@media (max-width: 820px) {

}
@media (max-width: 768px) {

}
@media (max-width: 575px) {
  .professional-plan-heading p {
    width: 100%;
    
    
}
.about-btn {
    position: relative;
    right: 0;
    top: 0;
    margin-bottom: 20px;
}
.seller-center-sec {
  padding-top: 10px;
  padding-bottom: 40px;
}
.seller-buy-now button {
    width: 100%;
 
}
.seller-center-modal-for-signin .modal-body {
  padding: 30px 10px !important;
  border-radius: 20px;
}
.seller-header-sec .container-fluid {
  padding: 0px 17px;
}
.check-seller label {
  font-size: 7px;
}
.for-extra-border-raduis {
  width: 50%;
}
.customer-order-row img {
  width: 50%;
}
.seller-prdoduct-inventory-table th {
  font-size: 7px;
}
.customer-order-row p {
  padding-left: 1px;
}
.seller-prdouct-customer-table button {
  font-size: 7px;
  width: 90px;
  margin-left: 10px;
}
.seller-prdouct-customer-table {
  overflow-x: scroll;
}
.order-total-modal .modal-body {
  padding: 20px 11px;
}
.order-head h2 {
  font-size: 23px;
}
.img-text-all h4 {
  font-size: 12px;
}
.order-text p {
  font-size: 8px;
}
.order-price h5 {
  font-size: 11px;
}
.order-total h5 {
  font-size: 9px;
}
.order-sum h3 {
  font-size: 14px;
}
.order-sum h4 {
  font-size: 14px;
}
.customer-order-row {
  padding: 0px 3px;
}
.seller-header-sec {
  padding-top: 19px;
  padding-bottom: 19px;
}
.seller-header-sec .seller-profile-name-wrapper {
  margin-top: 17px;
}
.reviews-all-stuff {
  padding: 20px 8px;
 
}
.reviews-para p {
  font-size: 8px;

}
.review-img {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 10px;
}
.all-seller-date-btn {
  display: block;
  justify-content: space-between;
}
.all-seller-btn {
  margin-top: 10px;
}
.review-modal .modal-content {
  padding: 7px 5px;
}
.modal-submit-btn button {
  width: 55%;
}
.payment-input input {
  margin: 10px 0px;
}
.payment-btn button {
  width: 57%;
}
.saved-address label.container .select-address {
  height: 100px;
}
.saved-address label.container .select-address .selection {
  top: 60px;

}
.all-professional-plan {
  padding: 9px 5px;
}
.upgrade-btn button {
  width: 50%;
}
.all-professioanl-cancel span p {
  font-size: 7px;
}
.getting-dv-head h3 {
  padding: 10px 0px;
}
.membership-modal .modal-body {
  padding: 40px 7px;
}
.membership-modal-head h2 {
  font-size: 20px;
}
.all-professional-plan::before {
  left: 130px;
  top: 0;
}
.seller-edit-with-border-dv {
  padding: 30px 11px;
}
.all-amount-paid span h5 {
  padding-right: 30px;
}
.all-professional-plan span p {
  padding-right: 27px;
}
.seller-forget-form  {
  width: 100%;

}
.modal-forget-link a {
  font-size: 10px;
}
.seller-forget-input input {
  padding: 5px;

}
.sell-pass {
  position: absolute;
  top: 20px;
  right: 15px;
}
.all-amount-paid span h5 {
  font-size: 11px;
}
.reviews-modal-para p {
  font-size: 16px;
}
.seller-center-all-membership-plan {
  margin: 15px 0px;
}
.seller-sidebar-sec .sidebar-links-wrapper ul li.sidebar-links a span.text {
  padding-top: 33px;
}
.check-seller input[type=checkbox] {
  margin: 0 0.1em 0 0;

}
.seller-sidebar-sec .sidebar-links-wrapper ul li.sidebar-links a span.text {

  padding-top: 33px !important;
}

}
@media (max-width: 393px) {
  .all-professional-plan::before {
    left: 166px !important;
}
}
@media (max-width: 390px) {
  .all-professional-plan::before {
    left: 166px !important;
}
}
@media (max-width: 375px) {
  .all-professional-plan::before {
    left: 156px !important;
}
}
@media (max-width: 360px) {
  .all-professional-plan::before {
    left: 156px !important;
}
}
/* ===================== */
