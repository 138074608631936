.banenr-text h5 {
    font-size: 20px;
    color: var(--tenth-color);
    font-family: "poppins-meduim";
    margin: 0;
}

.banenr-text h2 {
    font-size: 31px;
    color: var(--second-color);
    font-family: "poppins-bold";
    margin: 0;
}

.discover-btn a {
    background-color: var(--first-color);
    padding: 10px 28px;
    border-radius: 7px;
    color: var(--third-color);
    font-family: "poppins-regular";
    text-decoration: unset;
}

section.fashion-product-sec-banner .row {
    display: flex;
    align-items: center;
}

.discover-btn {
    padding-top: 32px;
}

.all-product-accordian .accordion-item {
    border: none;
}

.all-product-accordian .accordion-button {
    background-color: transparent;
    box-shadow: unset;
}

.input-1 input {
    font-size: 15px;
    width: 90%;
    text-align: center;
    border: 1px solid #707070;
    padding: 8px;
    border-radius: 2px;
}

.price-input {
    display: flex;
    align-items: center;
}

.input-1-btn button {
    background-color: var(--first-color);
    border: unset;
    padding: 9px 18px;
    color: var(--third-color);
    font-family: "poppins-regular";
    border-radius: 5px;
}

.all-price-li ul {
    padding: 0;
    list-style: none;
}

.all-price-li ul li {
    font-size: 15px;
    color: var(--tenth-color);
    font-family: "poppins-meduim";
    padding: 7px 0px;
}

.all-product-accordian .form-group #bottom-check:before {
    -webkit-appearance: none;
    background-color: initial;
    border: 2px solid #dcdcdc;
    border-radius: 2px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05);
    content: "";
    display: inline-block;
    margin-right: 10px;
    padding: 7px;
    vertical-align: middle;
}

.all-product-accordian .form-group {
    display: block;
    margin-bottom: 15px;
}

.all-product-accordian .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.all-product-accordian .form-group label {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.all-product-accordian .form-group label:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #707070;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 7px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 7px;
}

.all-product-accordian .form-group input:checked+label:after {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 7px;
    width: 4px;
    height: 12px;
    border: solid #707070;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.check-and label {
    color: #707070;
    font-family: "segoe-regular";
}

.all-product-accordian button.accordion-button.collapsed {
    font-size: 15px;
    color: var(--second-xolor);
    font-family: "poppins-semibold";
}

.all-product-accordian .accordion-button:not(.collapsed) {
    color: var(--second-color);
    background-color: transparent;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
    font-family: "poppins-semibold";
}

.all-product-accordian button.accordion-button.collapsed::before {
    content: '\f107 ';
    font-family: 'FontAwesome';
    position: absolute;
    right: 0;
}
.inner-accordian button.accordion-button.collapsed {
    font-size: 15px;
    color: #707070;
    font-family: 'segoe-regular';
    z-index: 1;
}

.inner-accordian button.accordion-button.collapsed::before {
    content: '\f107 ';
    font-family: 'FontAwesome';
    position: absolute;
    right: 0;
}

.inner-accordian .accordion-button:not(.collapsed) {
    color: #707070;
    background-color: transparent;
    box-shadow: unset;
    font-family: 'segoe-semibold';
}

.all-product-accordian .accessories-body {
    padding: 10px 26px;
    list-style: none;
}

.accessories-body li {
    font-size: 13px;
    color: #707070;
    font-family: 'poppins-light';
    line-height: 28px;
}

.all-product-accordian .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: unset !important;
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
}

.all-product-accordian .accordion-body {
    padding: 10px 20px;
}

.product-filter p {
    font-size: 15px;
    color: var(--second-color);
    font-family: 'poppins-semibold';
    margin: 0;
}

.product-filter {
    display: flex;
    justify-content: space-between;
}

.product-filter p span {
    font-size: 13px;
    font-family: 'poppins-meduim';
}

.sort-all button#dropdownMenuButton2 {
    font-size: 13px;
    color: var(--second-color);
    background-color: transparent;
    font-family: 'poppins-meduim';
    border-radius: 4px;
}

.sort-all {
    display: flex;
    align-items: baseline;
    padding-left: 16px;
}

.sort-all p {
    font-size: 13px;
    color: var(--second-color);
    font-family: 'poppins-semibold';
    margin: 0;
    padding-right: 10px;
}

.sec-first-row {
    align-items: center;
}
.product-sec .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    /* vertical-align: .255em; */
    content: "\f107";
    font-family: 'FontAwesome';
    border-top: unset;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

.sort-flex {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
}

.input-1 input::placeholder {
    font-size: 14px;
    color: var(--second-color);
    font-family: 'poppins-semibold';
}

span.two-grid-icons i {
    padding: 0px 6px;
    color: var(--bs-black);
}

.fashion-product-all img {
    height: 250px;
    object-fit: contain;
    padding: 30px;
    padding-bottom: 0;
}

.fashion-product-all {
    height: 350px;
}

.all-product-accordian .accordion-button:not(.collapsed)::before {
    content: '\f068';
    font-family: 'FontAwesome';
    position: absolute;
    top: 18px;
    right: 0;
    color: #68686875;
}

.pagination-all {
    display: flex;
    justify-content: center;
    padding: 26px 0px;
}

.pagination-all .page-link:focus {
    z-index: 3;
    color: var(--third-color);
    font-family: 'poppins-meduim';
    background-color: var(--first-color);
    outline: 0;
    box-shadow: var(--bs-pagination-focus-box-shadow);
}

.pagination-all .page-link {
    font-size: 20px;
    color: #AAAAAA;
    padding: 0px 13px;
    font-family: 'poppins-regular';
    margin: 0px 13px;
    box-shadow: 0px 4px 12px #00000026;
    border-radius: 2px;
    border: none;
    height: 41px;
    width: 40px;
    line-height: 45px;
    text-align: center;
}

.custom-fashion-text h3 {
    font-size: 18px;
    color: var(--second-color);
    font-family: 'poppins-bold';
    margin: 0;
}
.small-slider::before {
    content: '\f105';
    font-family: 'FontAwesome';
    position: absolute;
    right: -21px;
    bottom: 9px;
    font-size: 20px;
    color: var(--first-color);
    font-weight: 100;
}
.small-embroidery-slider::before {
    opacity: 0 !important;
}
.color-sliders::before {
    content: '\f105';
    font-family: 'FontAwesome';
    position: absolute;
    right: -21px;
    bottom: -1px;
    font-size: 20px;
    color: var(--first-color);
    font-weight: 100;
}
.seller h5 {
    font-size: 16px;
    color: var(--second-color);
    font-family: 'poppins-semibold';
    margin: 0;
}

.fashion-product-sec-banner {
    padding: 50px 0px;
}
.banner-image img {
    width: 100%;
    height: 380px;
    object-fit: cover;
}
.all-banenr-back {
    box-shadow: 0px 0px 8px #00000038;
    padding: 30px 69px;
    border-radius: 30px;
    padding-bottom: 0;
    width: 98%;
    margin: 15px auto;
    height: 429px;
    padding-right: 20px;
}

.banner-image {
    text-align: center;
}

.product-sec a {
    text-decoration: unset;
}

/* Begin of Product detail css */
.customize-stars {
    padding: 0 !important;
}

.stars {
    padding: 0;
    list-style: none;
    display: flex;
    margin: 0;
    padding-left: 10px;
}

.stars li a {
    font-size: 9px;
    color: #FE8700;
    padding: 0px 1px;
}

.name-stars h5 {
    font-size: 13px;
    color: var(--second-color);
    font-family: 'poppins-meduim';
    margin: 0;
}

.name-stars p {
    font-size: 10px;
    color: var(--tenth-color);
    font-family: 'poppins-regular';
    margin: 0;
}

.seller-anchor a {
    font-size: 12px;
    color: var(--sixth-color);
    font-family: 'poppins-meduim';
}

.name-stars {
    display: flex;
    align-items: baseline;
    padding-bottom: 7px;
}

.name-stars h5 span {
    font-size: 18px;
    color: var(--sixth-color);
    font-family: 'poppins-semibold';
}

.seller-anchor {
    padding-left: 60px;
}

.size-chart-anchor {
    padding-left: 10px;
    padding-top: 9px;
}

.seller p {
    font-size: 10px;
    color: var(--tenth-color);
    font-family: 'poppins-meduim';
    margin: 0;
    padding-top: 8px;
}

.seller-price h2 {
    font-size: 30px;
    color: var(--first-color);
    font-family: 'poppins-semibold';
    margin: 0;
    padding: 35px 0px;
}

.seller {
    border-top: 1px solid #DEDEDE;
    border-bottom: 1px solid #DEDEDE;
}

.quainty-and-button {
    display: flex;
    align-items: baseline;
}

.quantity input {
    width: 28%;
    padding: 1px;
    height: 34px;
    border: unset;
    text-align: center;
    outline: unset;
    border: none;
    background-color: #F2F2F2;
    font-family: 'segoe-semibold';
}

.quantity button i {
    font-size: 12px;
    padding: 0px 0px;
    border: 1px solid #dcdcdc14;
    cursor: pointer;
    outline: 0;
    height: 20px;
    color: var(--second-color);
    font-weight: 800;
}

.quantity button {
    border: unset;
    background-color: #F2F2F2;
    height: 15px;
    line-height: 0px;
}

.quantity {
    padding-top: 30px;
}

.btn-qunatity-1 {
    position: relative;
    top: -11px;
    left: 6px;
}

.btn-qunatity-2 {
    position: relative;
    top: 7px;
    right: 16px;
}

.product-detail-sec {
    padding: 60px 0px;
    padding-bottom: 15px;
}
.only-for-product-align {
    padding-top: 20px;
}
.quantity-for-product-design {
    padding-right: 56px;
}
.quantity {
    padding-top: 27px;
    display: flex;
    align-items: center;
}
.quantity-class-border-only {
    border-top: 1px solid #DEDEDE;
    padding-bottom: 20px;
}
.quantity-head h5 {
    font-size: 15px;
    color: var(--second-color);
    font-family: 'poppins-meduim';
    margin: 0;
}

.quantity-all-stuff {
    padding-left: 55px;
}

.radio-btn input[type="radio"] {
    z-index: 1;
    position: relative;
    opacity: 0;
    cursor: pointer;
}

.radio-btn h5 {
    font-size: 13px;
    color: var(--second-color);
    font-family: 'poppins-semibold';
    text-transform: uppercase;
    line-height: 27px;
    margin: 0;
    padding: 0;
    height: 1px;
}
.radio-btn button {
    border: 3px solid #ECECEC;
    background-color: var(--third-color);
    border-radius: 5px;
    position: relative;
    width: 100%;
    margin: 0 1px;
    padding: 1px 6px;
}

.all-radio-btn {
    display: flex;
    align-items: center;
}

.radio-btn {
    margin: 0px 10px;
}

.all-radio-btn {
    display: flex;
    align-items: center;
    padding: 22px 0px;
}

.radio-name {
    padding-right: 80px;
}

.size-chart-anchor a {
    font-size: 10px;
    color: var(--sixth-color);
    font-family: 'poppins-meduim';
}

.whishlist-cart a {
    font-size: 16px;
    color: #7DAB3C;
    font-family: 'poppins-regular';
    margin: 0;
    text-decoration: unset;
    text-transform: capitalize;
}

.whishlist-cart {
    border: 1px solid #7DAB3C;
    padding: 8px 0px;
}

.customize-cart {
    margin: 0 11px !important;
}

.whishlist-cart {
    border: 1px solid #7DAB3C;
    padding: 8px 0px;
    width: 30%;
    text-align: center;
    margin-right: 20px;
    border-radius: 7px;
    transition: 1s;
}

.customize-cart {
    border: 1px solid #7DAB3C;
    padding: 6px 0px;
    width: 20%;
    text-align: center;
    margin-right: 20px;
    border-radius: 7px;
    transition: 1s;
    line-height: 20px;
}

.whishlist-and-cart-button {
    display: flex;
}

.whishlist-cart:hover {
    background-color: var(--first-color);
}

.whishlist-cart:hover a {
    color: var(--third-color);
}

.whishlist-and-cart-button {
    display: flex;
    padding-top: 10px;
}

.category-text h5 {
    font-size: 12px;
    color: #646464;
    font-family: 'poppins-semibold';
    margin: 0;
}

.category-text {
    border-top: 1px solid #DEDEDE;
    border-bottom: 1px solid #DEDEDE;
    padding: 20px 0px;
    margin-top: 30px;
}

.category-text h5 span {
    font-family: 'poppins-light';
}

.product-li {
    padding: 0;
    list-style: none;
    display: flex;
    margin: 0;
}

.product-li li a {
    background-color: var(--second-color);
    border-radius: 20px;
    padding: 8px;
    color: var(--third-color);
    margin-right: 11px;
}

.product-li li a i {
    width: 20px;
    height: 20px;
    text-align: center;
}

.product-ul {
    display: flex;
    align-items: baseline;
    padding-top: 18px;
}

.product-head h5 {
    font-size: 12px;
    color: #646464;
    font-family: 'poppins-semibold';
    margin: 0;
}

.product-head {
    padding-right: 10px;
}

.all-borders img {
    width: 95%;
    border-radius: 40px;
    background-color: var(--third-color);
    box-shadow: 0px 0px 10px #7070704d;
    padding: 0;
    margin: 15px auto;
}

.product-detail-sec button.slick-arrow.slick-prev {
    display: none !important;
}

.only-for-this-product button.slick-arrow.slick-next {
    display: none !important;
}
.fabricc-all button.slick-arrow.slick-next {
    font-size: 0px;
    position: absolute;
    right: -25px;
    top: 20px;
    opacity: 0;
}

.on-small-border {
    width: 70%;
    border-radius: 20px;
    box-shadow: 0px 0px 12px #00000029;
    height: 80px;
    margin: 10px auto;
}

.all-borders {
    border: none;
    outline: none;
}

.product-detail-sec .row {
    display: flex;
    align-items: flex-start;
}
.custom-fashion-text {
    padding-top: 21px;
}

/* =================== */

/* Begin of Product Detail two */
.seller-pricing {
    display: flex;
}

.seller-price-1 h5 {
    font-size: 17px;
    color: vaR(--tenth-color);
    font-family: 'poppins-meduim';
    padding-bottom: 12px;
}

.seller-price-1 h2 {
    font-size: 21px;
    color: var(--second-color);
    font-family: 'poppins-semibold';
    transition: 1s;
}

.seller-price-1 {
    padding-right: 50px;
}
.fabric-images img {
    border-radius: 15px;
}
.seller-pricing {
    display: flex;
    padding: 20px 0px;
    padding-bottom: 30px;
    transition: 1s;
}

.seller-price-1:hover h2 {
    color: var(--first-color);
}

/* ==================== */


/* Begin of Reviews Css */
.review-para p {
    font-size: 12px;
    color: var;
    color: var(--fourth-color);
    font-family: 'poppins-semibold';
}

.tab-text h5 {
    font-size: 13px;
    color: var(--second-color);
    font-family: 'poppins-semibold';
}

.tab-text p {
    font-size: 10px;
    color: var(--second-color);
    font-style: initial;
    font-family: 'poppins-meduim';
    letter-spacing: 1px;
    margin: 0;
}

.review-tab-all {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    background-color: transparent;
    border: unset;
}

.tabs-alls {
    background-color: var(--third-color);
    padding: 0px 0px;
    margin: 70px 0px;
}

.reviews-tab-sec .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #1F162C;
    background-color: #f2f2f2;
    border-radius: 0px;
    font-family: 'poppins-semibold';
    font-size: 12px;
    padding: 18px 30px;
    border-radius: 20px 20px 0px 0px;
}

.reviews-tab-sec .nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: var(--bs-nav-pills-border-radius);
    color: var(--second-color);
    font-family: 'poppins-semibold';
    font-size: 12px;
    padding: 18px 30px;
    text-transform: capitalize;
}

.for-margin {
    margin-bottom: 30px;
    border-bottom: 1px solid #DEDEDE;
    align-items: center;
    padding-bottom: 30px;
}

.tab-text h5 span {
    font-size: 10px;
    font-family: 'poppins-regular';
    color: #6D6D6D;
    padding-left: 10px;
}

.input-reviews input {
    width: 100%;
    padding: 8px 28px;
    border: 1px solid #ced4da;
    outline: none;
}

.add-review-btn {
    float: right;
    width: 37%;
    padding-bottom: 40px;
}

.tabs-alls ul {
    border-bottom: 1px solid #DCDCDC;
}

.input-reviews label {
    font-size: 14px;
    color: var(--fourth-color);
    font-family: 'poppins-meduim';
    padding-bottom: 10px;
}

.form-text-area label {
    font-size: 14px;
    color: var(--fourth-color);
    font-family: 'poppins-meduim';
    padding-bottom: 10px;
}

.form-text-area textarea {
    height: 200px;
    border-radius: unset;
    resize: unset;
    padding: 16px 28px;
}

.add-text {
    padding-bottom: 28px;
}

.form-text-area {
    padding-top: 30px;
}

.input-reviews input::placeholder {
    font-size: 10px;
    font-family: 'poppins-regular';
}

.form-text-area textarea::placeholder {
    font-size: 10px;
    font-family: 'poppins-regular';
}

.add-text h5 {
    font-size: 17px;
    color: var(--fourth-color);
    font-family: 'poppins-bold';
}

.add-text p {
    font-size: 16px;
    color: var(--fourth-color);
    font-family: 'poppins-regular';
}

.review-form {
    padding-top: 70px;
}

.review-para {
    padding-top: 30px;
}

.description-para p {
    font-size: 12px;
    color: #646464;
    font-family: 'poppins-regular';
    margin-bottom: 20px;
}

.description-para {
    background-color: #F2F2F2;
    padding: 42px 20px;
    border-radius: 0px 20px 20px 20px;
}

.reviews-tab-sec .tabs-alls ul {
    border-bottom: unset;
    margin: 0 !important;
}

.reviews-all {
    background-color: #f2f2f2;
    padding: 30px 40px;
    border-radius: 20px;
}

.for-space-in-slider {
    margin: 0px 6px;
}

/* ============================== */

/* Begin of Product Customize Css  */
.pieces-seller-price {
    font-size: 17px;
    color: var(--second-color);
    font-family: 'poppins-bold';
}

.customize-cart a {
    font-size: 10px;
}

.sub-total-all {
    display: flex;
    justify-content: space-between;
    padding: 7px 0px;
}
.you-shop-allow h4 span {
    font-size: 13px;
    font-family: 'poppins-semibold';
}
.estimated-total {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
    border-top: 1px solid #7070705c;
    margin-top: 14px;
    padding-top: 5px;
}

.sub-total-all span h5 {
    font-size: 14px;
    color: #646464;
    font-family: 'poppins-regular';
    margin: 0;
}

.estimated-total span h5 {
    font-size: 17px;
    color: var(--second-color);
    font-family: 'poppins-semibold';
}

.traditional-dress h4 {
    font-size: 24px;
    color: var(--second-color);
    font-family: 'poppins-bold';
    margin: 0;
    padding-bottom: 0px;
}

.you-shop-allow h4 {
    font-size: 24px;
    color: var(--second-color);
    font-family: 'poppins-bold';
    margin: 0;
    padding-bottom: 13px;
}

.traditional-dress h5 {
    font-size: 25px;
    color: var(--first-color);
    font-family: 'poppins-meduim';
    margin: 0;
    padding: 10px 0px;
}

.traditional-dress p {
    font-size: 16px;
    color: var(--second-color);
    font-family: 'poppins-semibold';
    margin: 0;
    padding: 2px 0px;
}

.traditional-dress p span {
    font-family: 'poppins-regular';
}

.traditional-button button {
    font-size: 16px;
    color: var(--third-color);
    font-family: 'poppins-regular';
    width: 100%;
    padding: 12px;
    background-color: var(--first-color);
    border: none;
    text-transform: capitalize;
    border-radius: 7px;
}

.customzation-image {
    background-color: var(--fifth-color);
    border-radius: 10px;
}

.customzation-image {
    background-color: var(--fifth-color);
    border-radius: 10px;
    text-align: center;
}

.product-customize-all-things .modal-body {
    padding: 40px 30px;
    background-color: #EFEFEF;
    border-radius: 20px;
}

.product-customize-all-things .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
    border-radius: 20px;
    border: none !important;
}

.custom-modal-heading h3 {
    font-size: 40px;
    color: var(--second-color);
    font-family: 'poppins-semibold';
    margin: 0;
    text-transform: capitalize;
}

.custom-modal-heading {
    text-align: center;
    padding-bottom: 40px;
}

/* ============= */

/* Begin of Art and Craft Css */
.art-craft-img img {
    border-radius: 20px;
}

/* ================= */

/* Begin of Customize Css */
.customize-text-area textarea {
    background-color: var(--third-color);
    height: 110px;
    resize: none;
}

.customize-text-area textarea#exampleFormControlTextarea1 {
    box-shadow: 0px 3px 10px #00000047;
    padding: 22px;
}

.customize-text-area textarea#exampleFormControlTextarea1::placeholder {

    font-size: 14px;
    color: #6C757D;
    font-family: 'poppins-regular';
    margin: 0;

}

.customize-text-area textarea#exampleFormControlTextarea1 {
    box-shadow: 0px 3px 10px #00000047;
    padding: 22px;
}

.img-customize-dv img {
    background-color: #ffffff;
    border-radius: 20px;
    height: 170px;
    object-fit: cover;
}

.img-customize-dv {
    background-color: #C1C1C1;
    border: 1px solid #707070;
    padding: 6px 6px 10px 6px;
    border-radius: 10px;
    margin-right: 20px;
    width: 15%;
}

.customization-dv-text {
    padding-top: 10px;
}

.img-customize-dv {
    text-align: center;

}

.img-customize-dv figure {
    background-color: #ffffff;
    border-radius: 20px;
}

.customize-all-imgs {
    display: flex;
}

.customize-design-sec .accordion-header {
    box-shadow: 0px 0px 0px #00000000;
    margin: 26px 0px;
    border-radius: 40px;
}

.customize-design-sec .accordion-button:not(.collapsed) {
    color: var(--second-color);
    background-color: var(--third-color);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.customize-design-sec .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
    border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
    border-radius: 10px;
    font-family: 'poppins-semibold';
}

/* .customize-design-sec .accordion-button:focus {
    z-index: 3;
    border-color: var(--third-color) !important;
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow);
} */
.customize-design-sec .accordion-button:focus {
    z-index: 3;
    border-color: var(--third-color) !important;
    outline: 0;
    box-shadow: unset;
}

.customize-design-sec .accordion-item {
    color: var(--bs-accordion-color);
    box-shadow: 0px 5px 10px #0000001f;
    background-color: #f2f2f2;
    border: none;
    border-radius: 40px !important;
}

.custom-design-tabs .accordion-item:first-of-type .accordion-button {
    border-radius: 5px !important;
    font-family: 'poppins-semibold';
}
.custom-design-tabs .accordion-body {
    padding: 0px 10px;
    padding-bottom: 19px;
}
/* .customize-design-sec .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: url("../img/Customize/greenplus.png");
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
} */
.customize-design-sec .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: unset;
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
}
.custom-design-tabs button.accordion-button.collapsed::before {
    content: '\f067';
    font-family: 'FontAwesome';
    color: var(--first-color);
    position: absolute;
    right: 40px;
    top: 20px;
}
.custom-design-tabs button.accordion-button::before {
    content: '\f068';
    font-family: 'FontAwesome';
    color: var(--first-color);
    position: absolute;
    right: 40px;
    top: 20px;
}

.customize-design-sec {
    background-color: #f2f2f2;
    padding: 60px;
    padding-top: 0px;
}

.customize-btn button {
    font-size: 13px;
    background-color: var(--first-color);
    font-family: 'poppins-regular';
    padding: 12px 18px;
    color: var(--third-color);
    border: none;
    border-radius: 7px;
}

.customize-btn {
    margin-top: 30px;
}

.customization-dv-text p {
    font-size: 12px;
    color: var(--third-color);
    font-family: 'poppins-regular';
    margin: 0;
}

.product-design-sec .row {
    display: flex;
    align-items: flex-start;
}

/* ============= */

/* Begin of Color Slider Css */
.slide-color-btn button {
    padding: 14px;
    border: 4px solid #dcdcdc;
    border-radius: 9px;
}

.black-button {
    background-color: #000000;
}

.sky-button {
    background-color: #1A7EC1;
}

.sky-green-button {
    background-color: #229586;
}

.yellow-button {
    background-color: #FFAA00;
}

.orange-button {
    background-color: #FF6600;
}

.red-button {
    background-color: #FF0000;
}

.purple-button {
    background-color: #7700FF;
}

.gray-button {
    background-color: #777B8E;
}

.brown-button {
    background-color: #740808;
}

.fabricc-all {
    padding-top: 12px;
    position: relative;
}
.fabric-slider {
    padding-top: 10px;
    position: relative;
}
.all-radio-design-button {
    padding-bottom: 30px;
}
.fabricc-all .row {
    display: flex;
    align-items: center;
}
.prdocut-slide-sec .slide-head h4::before {
    content: '\f104';
    font-family: 'FontAwesome';
    position: absolute;
    right: 63px;
    z-index: 1;
    color: var(--third-color);
}
.prdocut-slide-sec .slide-head h4::after {
    content: '\f105';
    font-family: 'FontAwesome';
    position: absolute;
    right: 12px;
    z-index: 1;
    color: var(--third-color);
}
/* =================== */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
/* Begin of Responsive Section */
@media (max-width: 1920px) {}

@media (max-width: 1600px) {}

@media (max-width: 1440px) {}

@media (max-width: 1366px) {}

@media (max-width: 1280px) {}

@media (max-width: 1100px) {
    .custom-fashion-text h3 {
        font-size: 14px;
    }

    .seller p {
        font-size: 9px;
    }

    .seller-price h2 {
        font-size: 18px;
        padding: 11px 0px;
    }

    .radio-name {
        padding-right: 13px;
    }

    .customize-cart {
        width: 100%;
    }

    .customize-cart {
        margin: 0 3px !important;
    }
    .name-stars h5 span {
        font-size: 12px;
    }
    .seller-anchor {
        padding-left: 10px;
    }
    .small-slider h4 {
        font-size: 12px;
    }
}

@media (max-width: 1024px) {
    .small-slider::before {
        opacity: 0;
    }
}

@media (max-width: 991px) {
    .product-detail-sec {
        padding-top: 50px;
        /* padding: 0px 0px; */
        padding-bottom: 0;
    }

    .custom-fashion-text h3 {
        font-size: 10px;
    }

    .all-radio-btn {
        padding: 0;
    }

    .quantity {
        padding-top: 9px;
        padding-bottom: 9px;
    }

    .customize-cart a {
        font-size: 8px;
    }

    .radio-btn button {

        padding: 0px 0px;
    }

    .size-chart-anchor {
        padding-left: 0px;
    }

    .all-banenr-back {
        padding: 20px 10px;

    }

    .banenr-text h5 {
        font-size: 16px;
    }

    .banenr-text h2 {
        font-size: 25px;
    }
    .seller-price-1 {
        padding-right: 30px;
    }
    .seller-price-1 h2 {
        font-size: 15px;
    }
    .seller-price-1 h5 {
        font-size: 11px;
    }
    .whishlist-cart {
        width: 100%;
  
    }
    .stars {
        padding-left: 2px;
    }
    .seller-anchor {
        padding-left: 0px;
    }
}

@media (max-width: 820px) {}

@media (max-width: 768px) {}

@media (max-width: 575px) {
    .quantity-all-stuff {
        padding-left: 15px;
    }

    .whishlist-and-cart-button {
        display: block;
    }

    .customize-cart {
        margin: 15px 3px !important;
    }

    .on-small-border {
        width: 90%;
        border-radius: 9px;
        height: 70px;
        padding: 5px;
    }

    .product-detail-sec {
        padding-top: 10px;
    }

    .radio-btn {
        margin: 0px 4px;
    }

    .sort-all {
        padding-left: 0px;
    }
    .sort-all button#dropdownMenuButton2 {
        font-size: 12px;
    }

    .banenr-text {
        text-align: center;
        padding-bottom: 50px;
    }

    .customize-design-sec {
        padding: 60px 0px;
        padding-top: 20px;
        margin: 12px;
    }

    .custom-design-tabs .accordion-item:first-of-type .accordion-button {
        font-size: 10px;
    }
    .customize-btn {
        margin-top: 30px;
        text-align: center;
    }

    .customer-tab-all-stuff p {
        font-size: 10px;
    }

    .img-customize-dv {
        width: 100%;
        margin: 10px 0px;
    }

    .customize-all-imgs {
        display: block;
    }

    .customize-design-sec .accordion-item {
        border-radius: 40px !important;
    }

    .custom-modal-heading h3 {
        font-size: 28px;
    }
    .product-customize-all-things .modal-body {
        padding: 40px 10px;
        margin-top: 160px;
    }

    .traditional-dress h4 {
        font-size: 20px;
    }

    .you-shop-allow h4 {
        font-size: 20px;
    }

    .sub-total-all span h5 {
        font-size: 10px;
    }

    .traditional-dress {
        padding-top: 10px;
    }

    .traditional-dress p {
        font-size: 10px;
    }
    .name-stars {
        display: block;
    }
    .whishlist-cart {
        width: 100%;
        margin: 10px 0px;
    }
    .slide-head h4::before {
        bottom: 16px;
    }
    .slide-head h4::after {
        right: 15px;
        bottom: 16px;
    }
    .banner-image img {
        height: 211px;
       
    }
    .radio-name {
        padding-right: 2px;
    }
    .radio-btn button {
        padding: 0px 1px;
        font-size: 14px;
        line-height: 25px;
    }
    .quantity {
        padding-top: 14px;
        padding-bottom: 14px;
    }
    .category-text h5 span {
        font-family: 'poppins-light';
        padding-left: 10px;
    }
    .product-filter {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }
    .product-sec .check-and label {
        font-size: 15px;
    }
    .quantity-head h5 {
        font-size: 11px;
    }
    .radio-btn h5 {
        font-size: 9px;
        line-height: 18px;
 
    }
    .radio-btn button {
        height: 23px;
       
    }
    .size-chart-anchor {
        padding-top: 3px;
    }
    .prdocut-slide-sec .slide-head h4::after {
        right: 15px;
    
    }
    .quantity-class-border-only {
        padding-top: 30px;
    }
    .all-radio-design-button {
        padding: 20px 0px;
    }
    .seller-pricing {
        padding-bottom: 16px;
        
    }
    .product-sec a {
        font-size: 10px;
        text-decoration: unset;
    }
    .all-product-accordian .form-group input:checked+label:after {
        top: 4px;
        left: 6px;
    }
    .all-product-accordian button.accordion-button.collapsed::before {
        content: '\f107 ';
        font-family: 'FontAwesome';
        position: absolute;
        right: 8px;
    }
    .sort-all {
        display: flex;
        align-items: center;
        
    }
    .sort-all button#dropdownMenuButton2 {
        width: 94%;
    }
    .sort-all p {
        font-size: 10px;
        padding-right: 3px;
    }
    span.two-grid-icons i {
        padding: 0px 2px;
  
    }
}

/* ===================== */