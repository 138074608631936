.terms-condition-all-para p {
    font-size: 12px;
    color: var(--second-color);
    font-family: 'poppins-regular';
}
.terms-sec {
    padding: 80px 0px;
    padding-top: 20px;
}
/* Begin of Responsive Section */
@media (max-width: 1920px) {

}
@media (max-width: 1600px) {

}
@media (max-width: 1440px) {

}
@media (max-width: 1366px) {

}
@media (max-width: 1280px) {

}
@media (max-width: 1100px) {
   
}
@media (max-width: 1024px) {
 

}
@media (max-width: 991px) {
    .terms-condition-all-para p {
        font-size: 9px;
    }

}
@media (max-width: 820px) {

}
@media (max-width: 768px) {

}
@media (max-width: 575px) {
    .content-wrapper h2 {
        font-size: 23px;
    }
}
/* ===================== */